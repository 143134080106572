import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AccountBox } from "@mui/icons-material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GenerateInvoice from "@pages/GenerateInvoice";
import ReceiptIcon from '@mui/icons-material/Receipt';

const user = JSON.parse(localStorage.getItem("user"));
let menu = [];

if (user?.role === "admin") {
  menu = [
    {
      cat: "Dashboard",
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    {
      cat: "Orders",
      icon: <ListAltIcon />,
      link: "/orders",
    },
    // {
    //   cat:"Generate Invoice",
    //   icon:<ReceiptIcon/>,
    //   link:"/generateInvoice"
    // },
    {
      cat: "Profile",
      icon:<AccountBox/>,
      link: "/profile",
    },
  ];
} else if (user?.role === "chemist") {
  menu = [
    {
      cat: "Dashboard",
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    // {
    //   cat: "Orders",
    //   icon: <ListAltIcon />,
    //   link: "/orders",
    // },
    {
      cat: "Profile",
      icon:<AccountBox/>,
      link: "/profile",
    },
  ];
} else if (user?.role === "driver") {
  menu = [
    {
      cat: "Dashboard",
      icon: <DashboardIcon />,
      link: "/dashboard",
    },
    {
      cat: "Pick up Order",
      icon: <ListAltIcon />,
      link: "/orders",
    },
    {
      cat: "Deliver Order",
      icon:<CheckBoxIcon/>,
      link: "/deliveredorders",
    },
    {
      cat: "Profile",
      icon:<AccountBox/>,
      link: "/profile",
    },
  ];
}

export default menu;
