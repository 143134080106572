// components
import Page from "@layout/Page";
import Statistics from "@widgets/Statistics";
import PaymentsHistory from "@widgets/PaymentsHistory";
import { useState } from "react";
import styles from "./styles.module.css";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useToast } from "@chakra-ui/react";
import { apiGET, apiPOST } from "@utils/apiHelper";

const AdminDashboard = () => {
  const [type, selectedType] = useState("");
  const [anylytics, setAnalytics] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userRole, setUserRole] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [changeState, setChangeState] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    province: "",
    phoneNo: "",
    role: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleFormChange = (field, value, index) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleOpenAddUserModal = () => {
    setAddUserModalOpen(true);
  };

  const handleCloseAddUserModal = () => {
    setAddUserModalOpen(false);
    setFormData({
      name: "",
      email: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      province: "",
      phoneNo: "",
      role: "",
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const getUserData = async () => {
    try {
      const filetrUrl = `filter={role:${userRole}}`;

      const response = await apiGET(
        `/v1/admin/getAlluser?limit=${changeState ? 10 : rowsPerPage}&page=${changeState ? 1 : page
        }&${userRole ? filetrUrl : ""}`
      );
      if (response?.status) {
        setChangeState(false);
        setTotalPages(response?.data?.data?.totalPages);
        setPage(response?.data?.data?.page);
        setTotalItems(response?.data?.data?.totalResults);
        setRowsPerPage(response?.data?.data?.limit);
        setUserData(response?.data?.data?.data);
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getAnalyticsData = async () => {
    try {
      const response = await apiGET("/v1/admin/analytics");
      if (response.status) {
        setAnalytics(response?.data?.data?.data);
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleAddUser = async () => {
    setIsLoading(true);
    try {
      const response = await apiPOST("/v1/auth/register", formData);
      if (response.status === 201) {
        handleCloseAddUserModal();
        getUserData();
        toast({
          title: "User Added Successfully!!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setFormData({
          name: "",
          email: "",
          streetAddress: "",
          postalCode: "",
          city: "",
          province: "",
          phoneNo: "",
          role: "",
        });
      } else {
        toast({
          title: response.data.data,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddUser();
  };

  useEffect(() => {
    getUserData();
  }, [userRole, page, rowsPerPage]);

  useEffect(() => {
    getAnalyticsData();
  }, []);

  return (
    <Page title="">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h1>Users</h1>
        <Grid item>
          <Button
            onClick={handleOpenAddUserModal}
            style={{
              color: "white",
              backgroundColor: "#1ec5d4",
              fontWeight: "bold",
            }}
          >
            + Add User
          </Button>
        </Grid>
      </div>

      <div className={styles.mainCard}>
        <div className={styles.largescreen}>
          <div
            key="stat-cause"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserRole("");
              setChangeState(true);
            }}
          >
            <Statistics
              onClick={() => {
                selectedType("user");
              }}
              data={{
                type: "cause",
                value: `${anylytics?.user}`,
                text: "Users",
              }}
            />
          </div>
          <div
            key="stat-teeth"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserRole("chemist");
              setChangeState(true);
            }}
          >
            <Statistics
              onClick={() => {
                selectedType("chemist");
                setChangeState(true);
              }}
              data={{
                type: "teeth",
                value: `${anylytics?.chemist}`,
                text: "Chemists",
              }}
            />
          </div>
          <div
            key="stat-heart"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserRole("driver");
            }}
          >
            <Statistics
              onClick={() => {
                selectedType("driver");
              }}
              data={{
                type: "heart",
                value: `${anylytics?.driver}`,
                text: "Drivers",
              }}
            />
          </div>
        </div>

        <div key="payments-history" className={styles.fullwidth}>
          <PaymentsHistory
            variant="compact"
            userData={userData}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            totalItems={totalItems}
            getUserData={getUserData}
          />
        </div>
      </div>

      <Dialog
        open={isAddUserModalOpen}
        onClose={handleCloseAddUserModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Name"
                  fullWidth
                  size="small"
                  required
                  value={formData?.name}
                  onChange={(e) => handleFormChange("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  fullWidth
                  size="small"
                  required
                  value={formData?.email}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                  onBlur={(e) => validateEmail(e.target.value)}
                  error={formData?.emailError}
                  helperText={
                    formData?.emailError && "Enter a valid email Street Address"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  required
                  size="small"
                  value={formData?.phoneNo}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, "");
                    let formattedValue = "";

                    for (let i = 0; i < value.length && i < 10; i++) {
                      if (i === 3 || i === 6) {
                        formattedValue += "-";
                      }
                      formattedValue += value[i];
                    }

                    if (value.length <= 13) {
                      handleFormChange("phoneNo", formattedValue);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Street Address"
                  fullWidth
                  required
                  size="small"
                  value={formData?.streetAddress}
                  onChange={(e) =>
                    handleFormChange("streetAddress", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  fullWidth
                  size="small"
                  required
                  value={formData?.city}
                  onChange={(e) => handleFormChange("city", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Province"
                  fullWidth
                  size="small"
                  required
                  value={formData?.province}
                  onChange={(e) => handleFormChange("province", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  fullWidth
                  size="small"
                  required
                  value={formData?.postalCode}
                  onChange={(e) => {
                    let value = e.target.value;

                    value = value.toUpperCase();

                    value = value.replace(/[^A-Z0-9]/g, "");

                    value = value.replace(/(.{3})/g, "$1 ");

                    value = value.trim();

                    if (value.length <= 7) {
                      handleFormChange("postalCode", value);
                    }
                  }}
                  inputProps={{
                    type: "text",
                    inputMode: "verbatim",
                    pattern: "[A-Za-z0-9-]*",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel>Role</InputLabel>
                <Select
                  placeholder="Select Role"
                  fullWidth
                  size="small"
                  required
                  value={formData.role}
                  onChange={(e) => handleFormChange("role", e.target.value)}
                >
                  <MenuItem value="">Select Role</MenuItem>
                  <MenuItem value="chemist">Chemist</MenuItem>
                  <MenuItem value="driver">Driver</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={handleCloseAddUserModal}
                style={{ padding: "10px", backgroundColor: "#1ecdd4" }}
              >
                Cancel
              </Button>
              <Button
                style={{ padding: "10px", backgroundColor: "#1ecdd4" }}
                type="submit"
              >
                {isLoading && (
                  <CircularProgress sx={{ color: "white" }} size={18} />
                )}
                {!isLoading && <div>Add User</div>}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default AdminDashboard;
