import pat1jpg from '@assets/avatars/pat1.jpg';
import pat1webp from '@assets/avatars/pat1.jpg?as=webp';
import pat2jpg from '@assets/avatars/pat2.jpg';
import pat2webp from '@assets/avatars/pat2.jpg?as=webp';
import pat3jpg from '@assets/avatars/pat3.jpg';
import pat3webp from '@assets/avatars/pat3.jpg?as=webp';
import pat4jpg from '@assets/avatars/pat4.jpg';
import pat4webp from '@assets/avatars/pat4.jpg?as=webp';
import pat5jpg from '@assets/avatars/pat5.jpg';
import pat5webp from '@assets/avatars/pat5.jpg?as=webp';
import pat6jpg from '@assets/avatars/pat6.jpg';
import pat6webp from '@assets/avatars/pat6.jpg?as=webp';
import pat7jpg from '@assets/avatars/pat7.jpg';
import pat7webp from '@assets/avatars/pat7.jpg?as=webp';
import pat9jpg from '@assets/avatars/pat9.jpg';
import pat9webp from '@assets/avatars/pat9.jpg?as=webp';
import pat10jpg from '@assets/avatars/pat10.jpg';
import pat10webp from '@assets/avatars/pat10.jpg?as=webp';

export const patients = [
    {
        id: 'YdnojZ',
        firstName: 'Marta',
        lastName: 'Addams',
        gender: 'female',
        age: 24,
        reg: new Date(2022, 5, 11),
        avatar: {
            jpg: pat1jpg,
            webp: pat1webp
        }
    },
    {
        id: 'Yd2nojT',
        firstName: 'Mildred',
        lastName: 'Collins',
        gender: 'female',
        age: 18,
        reg: new Date(2022, 5, 21),
        avatar: {
            jpg: pat4jpg,
            webp: pat4webp
        }
    },
    {
        id: 'Y32nojT',
        firstName: 'Derek',
        lastName: 'Osborn',
        gender: 'male',
        age: 35,
        reg: new Date(2022, 2, 30),
        avatar: {
            jpg: pat2jpg,
            webp: pat2webp
        }
    },
    {
        id: 'Yd2noBN',
        firstName: 'Elisabeth',
        lastName: 'Aron',
        gender: 'female',
        age: 21,
        reg: new Date(2022, 2, 5),
        avatar: {
            jpg: pat10jpg,
            webp: pat10webp
        }
    },
    {
        id: 'lP2noBN',
        firstName: 'William',
        lastName: 'Dove',
        gender: 'male',
        age: 49,
        reg: new Date(2022, new Date().getMonth() - 1, 1),
        online: true,
        avatar: {
            jpg: pat3jpg,
            webp: pat3webp
        }
    },
    {
        id: 'l42noBX',
        firstName: 'John',
        lastName: 'Farewell',
        gender: 'male',
        age: 37,
        reg: new Date(2022, new Date().getMonth() - 1, 1),
        avatar: {
            jpg: pat5jpg,
            webp: pat5webp
        }
    },
    {
        id: 'l411oBR',
        firstName: 'Vanessa',
        lastName: 'Bloom',
        gender: 'female',
        age: 24,
        reg: new Date(2022, new Date().getMonth() - 1, 1),
        online: true,
        avatar: {
            jpg: pat9jpg,
            webp: pat9webp
        }
    },
    {
        id: 'x411oTT',
        firstName: 'Melody',
        lastName: 'Brick',
        gender: 'female',
        age: 19,
        reg: new Date(),
        avatar: {
            jpg: pat6jpg,
            webp: pat6webp
        }
    },
    {
        id: 'byTRoTT',
        firstName: 'Louis',
        lastName: 'Garner',
        gender: 'male',
        age: 41,
        reg: new Date(),
        message: true,
        avatar: {
            jpg: pat7jpg,
            webp: pat7webp
        }
    },
    {
        id: '52OOoTT',
        firstName: 'Marian',
        lastName: 'Lou',
        gender: 'female',
        age: 35,
        reg: new Date(),
        avatar: {
            jpg: pat10jpg,
            webp: pat10webp
        }
    },
    {
        id: 'g5Y1oXc',
        firstName: 'Mike',
        lastName: 'Lawson',
        gender: 'male',
        age: 22,
        reg: new Date(),
        online: true,
        avatar: {
            jpg: pat3jpg,
            webp: pat3webp
        }
    },
    {
        id: 'x4iomTT',
        firstName: 'Sarah',
        lastName: 'Bradley',
        gender: 'female',
        age: 57,
        reg: new Date(),
        online: true,
        avatar: {
            jpg: pat1jpg,
            webp: pat1webp
        }
    },
    {
        id: 'lan1oRe',
        firstName: 'Brian',
        lastName: 'Bright',
        gender: 'male',
        age: 28,
        reg: new Date(),
        reminder: {
            type: 'test',
            text: 'Ultrasound in 3 days'
        },
        avatar: {
            jpg: pat3jpg,
            webp: pat3webp
        }
    },
    {
        id: 'bbg1oTT',
        firstName: 'Bella',
        lastName: 'Crowley',
        gender: 'female',
        age: 28,
        reg: new Date(),
        online: true,
        message: true,
        avatar: {
            jpg: pat9jpg,
            webp: pat9webp
        }
    },
    {
        id: 'la3orT',
        firstName: 'Panam',
        lastName: 'Palmer',
        gender: 'female',
        age: 28,
        reg: new Date(),
        online: true,
        reminder: {
            type: 'consultation',
            text: 'Keeping pregnant in 4:22'
        },
        avatar: {
            jpg: pat4jpg,
            webp: pat4webp
        }
    },
    {
        id: 'x411Xlp',
        firstName: 'Jason',
        lastName: 'Ward',
        gender: 'male',
        age: 19,
        reg: new Date(),
        message: true,
        avatar: {
            jpg: pat5jpg,
            webp: pat5webp
        }
    },
    {
        id: 'ma11oTr',
        firstName: 'Crystal',
        lastName: 'Zeng',
        gender: 'female',
        age: 29,
        reg: new Date(),
        online: true,
        avatar: {
            jpg: pat10jpg,
            webp: pat10webp
        }
    },
    {
        id: 'sjiII4s',
        firstName: 'Amanda',
        lastName: 'Spencer',
        gender: 'female',
        age: 24,
        reg: new Date(2022, new Date().getMonth() + 1, 1),
        online: true,
        avatar: {
            jpg: pat1jpg,
            webp: pat1webp
        }
    },
    {
        id: 'folz25x',
        firstName: 'Richard',
        lastName: 'Brown',
        gender: 'male',
        age: 57,
        reg: new Date(2022, new Date().getMonth() + 1, 1),
        message: true,
        avatar: {
            jpg: pat2jpg,
            webp: pat2webp
        }
    },
    {
        id: 'ttr5YtB',
        firstName: 'Hannah',
        lastName: 'Rose',
        gender: 'female',
        age: 31,
        reg: new Date(2022, new Date().getMonth() + 1, 1),
        reminder: {
            type: 'consultation',
            text: 'Keeping pregnant in 4:22'
        },
        avatar: {
            jpg: pat4jpg,
            webp: pat4webp
        }
    },
];