import { useState, useEffect } from "react";
import DriverList from "@widgets/DriverList";
import Page from "@layout/Page";
import Statistics from "@widgets/DriverStatistics";
import styles from "./Driver.module.css";
import { apiGET } from "@utils/apiHelper";
import { preventDefault } from "@utils/helpers";
import { useToast } from "@chakra-ui/react";

const Driver = () => {
  const [type, selectedType] = useState("");
  const [anylytics, setAnalytics] = useState([]);
  const [analyticdeliveryStatus, setAnalyticdeliveryStatus] = useState('');
  const [changeState, setChangeState] = useState(false);
  const toast = useToast()

  const getAnalyticsData = async (e) => {
    preventDefault(e);
    try {
      const response = await apiGET("/v1/order/getAnalyticForDriver");
      if (response.status === 200) {
        setAnalytics(response?.data?.data?.data);
      }
    } catch (error) {
      toast({
        title: "Error Fetching User",
        description: `Error: ${error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getAnalyticsData();
  }, []);

  return (
    <>
      <Page title="Dashboard">
        <div className={styles.mainCard}>
          <div className={styles.largescreen}>
            <div
              key="stat-cause"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAnalyticdeliveryStatus(1);
                setChangeState(true);
              }}
            >
              <Statistics
                onClick={() => {
                  selectedType("user");
                }}
                data={{
                  type: "assignDelivery",
                  value: `${anylytics?.driverAssignedDelivery}`,
                  text: "Assigned Delivery",
                }}
              />
            </div>
            <div
              key="stat-teeth"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAnalyticdeliveryStatus(2);
                setChangeState(true);
              }}
            >
              <Statistics
                onClick={() => {
                  selectedType("chemist");
                }}
                data={{
                  type: "currentDelivery",
                  value: `${anylytics?.currentDelivery}`,
                  text: "Current Delivery",
                }}
              />
            </div>
            <div
              key="stat-heart"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAnalyticdeliveryStatus(3);
                setChangeState(true);
              }}
            >
              <Statistics
                onClick={() => {
                  selectedType("successfullDelivery");
                }}
                data={{
                  type: "successfullDelivery",
                  value: `${anylytics?.previousSuccessfullDelivery}`,
                  text: "Succesfull Delivery",
                }}
              />
            </div>
          </div>
        </div>
        <DriverList
          analyticdeliveryStatus={analyticdeliveryStatus}
          stateValue={changeState}
          setAnalyticdeliveryStatus={setAnalyticdeliveryStatus}
          getAnalyticsData={getAnalyticsData}
        />
      </Page>
    </>
  );
};

export default Driver;
