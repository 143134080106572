import React, { useState } from "react";
import Page from "@layout/Page";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { QrScanner } from "@yudiel/react-qr-scanner";


import QRScanner from "./QRScanner";
import { apiPUT } from "@utils/apiHelper";
import Swal from "sweetalert2";
import { Scale } from "@mui/icons-material";
import { useToast } from "@chakra-ui/react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DriverOrders() {
  const [modalOpen, setModalOpen] = useState(false);
  const [scannerError, setScannerError] = useState(null);
  const [deliveryData, setDeliveryData] = useState('');
  const toast = useToast()

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setScannerError(null);
  };

  const handleOutDelivery = async () => {
    const result = await apiPUT(
      `/v1/order/orderPickedByDriver/${deliveryData.id}`
    );
    if (result.status === 200) {
      Swal.fire({
        title: "Success",
        text: "Order picked successfully",
        icon: "success",
      });
      setDeliveryData();
    } else {
      Swal.fire({
        title: "Error",
        text: result.data.data,
        icon: "error",
      });
    }
  };

  const handleScan = (data) => {
    if (data) {
      setDeliveryData(JSON.parse(data));
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setScannerError("No QR code detected");
    }
  };

  const getOrderId = async () => {
    await Swal.fire({
      title: "Enter your Order ID",
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Please Enter Order Id";
        } else {
          handleDeliverOrderById(value);
        }
      }
    });
  }

  const handleDeliverOrderById = async (value) => {
    try {
      const response = await apiPUT(
        `/v1/order/orderPickedByOrderId/${value}`
      );

      if (response.status === 200) {
        Swal.fire({
          title: "Success",
          text: "Order picked successfully",
          icon: "success",
        });
        setDeliveryData();
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.data,
          icon: "error",
        });
      }
    } catch (error) {
      toast({
        title:"Error!",
        description:error,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleError = (err) => {
    setScannerError(err.message || "Failed to access camera");
  };

  return (
    <Page title="Delivery Report">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {deliveryData ? (
            <>
              <Button
                onClick={handleOutDelivery}
                variant="outlined"
                sx={{
                  marginTop: "15rem",
                  fontSize: "30px",
                  borderRadius: "30px",
                  padding: "5px",
                  "&:hover": {
                    backgroundColor: "rgb(223, 223, 223)",
                    transform: "scale(1.05)",
                  },
                }}
              >
                Out for Delivery
              </Button>
              <Button
                onClick={() => { setDeliveryData('') }}
                variant="outlined"
                sx={{
                  marginBottom: "3rem",
                  fontSize: "22px",
                  borderRadius: "30px",
                  marginTop: "1rem",
                  "&:hover": {
                    backgroundColor: "rgb(223, 223, 223)",
                    transform: "scale(1.05)",
                  },
                }}
              >
                Re-Scan
              </Button>
            </>
          ) : (
            <Button
              onClick={handleOpen}
              sx={{
                fontSize: "25px",
                color: "white",
                backgroundColor: "#1ecdd4",
                borderRadius: "30px",
                marginBottom: "3rem",
                padding: "12px",
                marginTop: "5rem",
                "&:hover": {
                  backgroundColor: "#20d9df",
                  color: "white",
                  transform: "scale(1.05)",
                },
              }}
            >
              Pickup Order
            </Button>
          )}{" "}

          <Button
            onClick={getOrderId}
            sx={{
              fontSize: "25px",
              borderRadius: "30px",
              color: "white",
              padding: "12px",
              backgroundColor: "#1ecdd4",
              "&:hover": {
                backgroundColor: "#20d9df",
                color: "white",
                transform: "scale(1.05)",
              },
            }}
          >
            Pick By OrderId
          </Button>
        </div>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <QRScanner />
            {scannerError && (
              <Typography color="error">{scannerError}</Typography>
            )}
            <QrScanner onDecode={handleScan} onError={handleError} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Scan the QR code
            </Typography>
            {scannerError && (
              <Typography color="error">{scannerError}</Typography>
            )}
          </Box>
        </Modal>
      </div>
    </Page>
  );
}
