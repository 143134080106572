import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';

const QRScanner = () => {
  const [hasWebcam, setHasWebcam] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [scannedData, setScannedData] = useState('');
  const facingModeConstraints = { facingMode: 'environment' };

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => devices.some((device) => device.kind === 'videoinput'))
      .then((supported) => setHasWebcam(supported));
  }, []);

  const handleScan = (data) => {
    if (data) {
      setScannedData(data);
      setIsScannerOpen(false);
    }
  };

  const openScanner = () => {
    setIsScannerOpen(true);
  };

  const closeScanner = () => {
    setIsScannerOpen(false);
  };

  return (
    <div>
      {hasWebcam ? (
        <div>
          {isScannerOpen ? (
            <QrReader
              delay={500}
              onScan={handleScan}
              style={{ width: '100%' }}
              constraints={facingModeConstraints}
              legacyMode={!navigator.mediaDevices.getSupportedConstraints().facingMode}
            />
          ) : (
            <button onClick={openScanner}>Open QR Scanner</button>
          )}
          {scannedData && <p>Scanned code: {scannedData}</p>}
          {isScannerOpen && <button onClick={closeScanner}>Close Scanner</button>}
        </div>
      ) : (
        <p>Webcam not supported in your browser.</p>
      )}
    </div>
  );
};

export default QRScanner;