import React, { useState, useEffect } from "react";
import Page from "@layout/Page";
import { Button, Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import { useToast } from "@chakra-ui/react";
import styled from "styled-components/macro";
import { apiGET, apiPUT, apiDownload } from "@utils/apiHelper";
import Swal from "sweetalert2";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  Paper,
  TablePagination,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  useMediaQuery,
  Checkbox,
  Typography,
} from "@mui/material";
import DatePicker from "react-multi-date-picker";
import Calendar from "react-multi-date-picker";
import QRCode from "react-qr-code";
import moment from "moment";
import { preventDefault } from "@utils/helpers";
import { hover } from "@testing-library/user-event/dist/hover";
import { Box, color } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container, Row } from "react-bootstrap";
import axios from 'axios';
import { message } from 'antd';
import { Download } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

const LabeledFormInputContainer = styled.div`
  margin-top: 20px;
`;

const Order = () => {
  const toast = useToast();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [orders, setOrders] = useState([]);

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [chemistPay, setChemistPay] = useState();
  const [driverPayout, setdriverPayout] = useState();
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState();

  const [searchCustomerName, setSearchCustomerName] = useState();
  const [searchDriverName, setSearchDriverName] = useState();
  const [searchChemistName, setSearchChemistName] = useState();
  const [searchOrderId, setSearchOrderId] = useState();
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchDate, setSearchDate] = useState([]);
  const [destructuredSearchDate, setDestructuredSearchedDate] = useState({});
  const [assignDriverLoader, setAssignDriverLoader] = useState(false)

  const handleDownload = async () => {
    setAssignDriverLoader(true)
    try {
      const queryParams = {};
      let filterQueryObject;

      if (selectedDeliveryStatus) {
        filterQueryObject = {
          ...filterQueryObject,
          deliveryStatus: selectedDeliveryStatus.value,
        };
      }
      if (searchOrderId) {
        filterQueryObject = { ...filterQueryObject, orderId: searchOrderId };
      }
      if (searchDriverName) {
        filterQueryObject = {
          ...filterQueryObject,
          driverName: searchDriverName,
        };
      }
      if (searchChemistName) {
        filterQueryObject = {
          ...filterQueryObject,
          chemistName: searchChemistName,
        };
      }
      if (searchCustomerName) {
        filterQueryObject = {
          ...filterQueryObject,
          customerName: searchCustomerName,
        };
      }

      if (destructuredSearchDate?.fromDate && destructuredSearchDate.toDate) {
        filterQueryObject = {
          ...filterQueryObject,
          fromDate: destructuredSearchDate?.fromDate,
          toDate: destructuredSearchDate?.toDate,
        };
      }
      queryParams.filter = JSON.stringify(filterQueryObject);
      const response = await apiDownload('/v1/order/downloadOrders', {
        params: queryParams,
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setAssignDriverLoader(false)
    } catch (error) {
      console.error('Error downloading orders:', error);
      message.error('Error downloading orders');
      setAssignDriverLoader(false)
    }
  };

  const handleSearchDate = () => {
    if (searchDate?.length == 2) {
      const startDate = `${searchDate[0].year}/${String(
        searchDate[0].day
      ).padStart(2, "0")}/${String(searchDate[0].monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      const endDate = `${searchDate[1].year}/${String(
        searchDate[1].day
      ).padStart(2, "0")}/${String(searchDate[1].monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      setDestructuredSearchedDate({ fromDate: startDate, toDate: endDate });
    }
  };

  useEffect(() => {
    handleSearchDate();
  }, [searchDate]);

  const handleDateChange = async (date, id) => {
    try {
      const today = new Date();
      const currentDate = `${today.getFullYear()}/${String(
        today.getMonth() + 1
      ).padStart(2, "0")}/${String(today.getDate()).padStart(2, "0")}`;
      const selectedDate = `${date.year.toString().padStart(4, "0")}/${String(
        date.month.number
      ).padStart(2, "0")}/${String(date.day).padStart(2, "0")}`;

      if (selectedDate < currentDate) {
        Swal.fire({
          title: "Do you want to save the previous date?",
          showDenyButton: true,
          confirmButtonText: "Save",
          denyButtonText: `Don't Save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await apiPUT(`v1/admin/updateDate/${id}`, {
                date: selectedDate,
              });
              if (response.status == 200) {
                Swal.fire("Saved!", "", "success");
              } else {
                Swal.fire("Error occurred while saving", "", "error");
              }
            } catch (error) {
              Swal.fire(error, "", "error");
            }
          } else if (result.isDenied) {
            getAllOrders();
            Swal.fire("Date Not Saved", "", "error");
          }
        });
      } else {
        try {
          const response = await apiPUT(`v1/admin/updateDate/${id}`, {
            date: selectedDate,
          });
          if (response.status == 200) {
            Swal.fire("Saved!", "", "success");
          } else {
            Swal.fire("Error occurred while saving", "", "error");
          }
        } catch (error) {
          Swal.fire("Error occurred while saving", "", "error");
        }
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAvailableDrivers = async () => {
    try {
      const response = await apiGET(`/v1/order/getAllDriver`);
      if (response.status === 200) {
        setAvailableDrivers(response?.data?.data?.data);
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAssignDriver = async (e) => {
    preventDefault(e);
    setAssignDriverLoader(true)
    try {
      if (selectedOrderDetails && selectedDriver) {
        const response = await apiPUT(
          `/v1/order/assignDriver/${selectedOrderDetails?._id}`,
          {
            driverId: selectedDriver?.id,
            chemistPay: chemistPay,
            driverPayout: driverPayout,
          }
        );
        if (response?.status === 200) {
          toast({
            title: "Driver Assigned!",
            description: `Driver ${selectedDriver?.name} has been assigned to the order.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          getAllOrders();
          handleCloseDriverModal();
        } else {
          toast({
            title: "Error!",
            description: "Failed to assign driver. Please try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setAssignDriverLoader(false)
    }
  };

  const handleOpenDriverModal = (orderDetails) => {
    getAvailableDrivers();
    setSelectedOrderDetails(orderDetails);
    setIsDriverModalOpen(true);
  };

  const handleCloseDriverModal = () => {
    setIsDriverModalOpen(false);
    setSelectedDriver(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "blue";
      case 1:
        return "green";
      case 2:
        return "orange";
      case 3:
        return "purple";
      case 4:
        return "red";
      case 5:
        return "brown";
      default:
        return "black";
    }
  };

  const status = 2;
  const color = getStatusColor(status);

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Delivery Assigned";
      case 2:
        return "Picked By Driver";
      case 3:
        return "Delivered";
      case 4:
        return "Failed";
      case 5:
        return "Return";
      default:
        return "Unknown Status";
    }
  };

  const handleCloseProductModal = () => {
    setIsProductModalOpen(false);
    setSelectedOrderDetails(null);
  };

  const handleOpenDetailsModal = (orderDetails) => {
    setSelectedOrderDetails(orderDetails);
    setIsProductModalOpen(true);
  };

  const markDeliverOrder = async (id) => {
    try {
      const response = await apiPUT(`/v1/order/adminDeliverOrder/${id}`);
      if (response.status === 200) {
        getAllOrders();
        toast({
          title: "Success!",
          description: `Order Delivered Successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const flattenedData = orders?.map((item, index) => ({
    _id: item._id,
    srNo: index + 1,
    orderId: item.orderId,
    customerName: item?.customer?.name,
    phoneNo: item?.customer?.phoneNo,
    address: item?.customer?.address,
    paybleAmount: item?.paybleAmount,
    deliveryStatus: item?.deliveryStatus,
    chemist: item?.chemist,
    customer: item?.customer,
    product: item?.product,
    driver: item?.driver,
    chemistPay: item?.chemistPay,
    driverPayout: item?.driverPayout,
    date: moment(item?.customCreatedAt).format("YYYY/MM/DD"),
    notes: item?.notes,
    driverNotes:item?.driverNotes,
    scheduleDelivery: item?.scheduleDelivery,
    sign: item?.sign,
  }));

  const rowsPerPageOptions = [10, 20, 30];

  const displayedData = flattenedData;

  const dynamicKeys = Object.keys(displayedData[0] || {});

  const deliveryStatusOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Delivery Assigned" },
    { value: 2, label: "Picked By Driver" },
    { value: 3, label: "Delivered" },
    { value: 4, label: "Failed" },
    { value: 5, label: "Return" },
  ];

  const getQueryString = (params) => {
    return Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  };

  const getAllOrders = async () => {
    setIsLoading(true);
    try {
      const queryParams = {
        limit: rowsPerPage ? rowsPerPage : 10,
        page: page ? page : 1,
      };
      let filterQueryObject;

      if (selectedDeliveryStatus) {
        filterQueryObject = {
          ...filterQueryObject,
          deliveryStatus: selectedDeliveryStatus.value,
        };
      }
      if (searchOrderId) {
        filterQueryObject = { ...filterQueryObject, orderId: searchOrderId };
      }
      if (searchDriverName) {
        filterQueryObject = {
          ...filterQueryObject,
          driverName: searchDriverName,
        };
      }
      if (searchChemistName) {
        filterQueryObject = {
          ...filterQueryObject,
          chemistName: searchChemistName,
        };
      }
      if (searchCustomerName) {
        filterQueryObject = {
          ...filterQueryObject,
          customerName: searchCustomerName,
        };
      }

      if (destructuredSearchDate?.fromDate && destructuredSearchDate.toDate) {
        filterQueryObject = {
          ...filterQueryObject,
          fromDate: destructuredSearchDate?.fromDate,
          toDate: destructuredSearchDate?.toDate,
        };
      }
      queryParams.filter = JSON.stringify(filterQueryObject);

      const response = await apiGET(
        `/v1/order/getAllOrder?${getQueryString(queryParams)}`
      );

      if (response.status === 200) {
        setOrders(response?.data?.data?.data);
        setPage(response?.data?.data?.page);
        setrowsPerPage(response?.data?.data?.limit);
        setTotalResult(response?.data?.data?.totalResults);
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getEndIndex = () => {
    const endIndex = page * rowsPerPage;
    return endIndex > totalResult ? totalResult : endIndex;
  };

  const getStartIndex = () => {
    return (page - 1) * rowsPerPage + 1;
  };

  const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  useEffect(() => {
    getAllOrders();
  }, [
    searchCustomerName,
    searchDriverName,
    searchChemistName,
    searchOrderId,
    selectedDeliveryStatus,
    page,
    rowsPerPage,
    destructuredSearchDate?.fromDate,
    destructuredSearchDate?.toDate,
  ]);

  const handleDeleteOrder = async (orderId) => {
    try {
      Swal.fire({
        title: "Are you sure to want to delete this order?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await apiPUT(`/v1/order/deleteOrder/${orderId}`);
            if (response.status == 200) {
              Swal.fire("Order Deleted Succesfully", "", "success");
              getAllOrders();
            }
          } catch (error) {
            Swal.fire(error, "", "error");
          }
        } else if (result.isDenied) {
          return;
        }
      });
    } catch (error) {
      Swal.fire(error, "", "error");
    }
  };

  return (
    <Page title="">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item style={{ marginTop: "0px" }}>
          <h1>Orders</h1>
        </Grid>
      </div>
      <br />
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "20px",
            overflowX: "auto",
            minWidth: "320px",
            padding: "7px",
          }}
        >
          <TextField
            label="Search Driver Name"
            variant="outlined"
            size="small"
            style={{ minWidth: "150px" }}
            value={searchDriverName}
            onChange={(e) => setSearchDriverName(e.target.value)}
          />

          <TextField
            label="Search Chemist Name"
            variant="outlined"
            size="small"
            style={{ minWidth: "150px" }}
            value={searchChemistName}
            onChange={(e) => setSearchChemistName(e.target.value)}
          />

          <TextField
            label="Search Order Id"
            variant="outlined"
            size="small"
            style={{ minWidth: "150px" }}
            value={searchOrderId}
            onChange={(e) => setSearchOrderId(e.target.value)}
          />

          <Autocomplete
            options={deliveryStatusOptions}
            getOptionLabel={(option) => option.label}
            value={selectedDeliveryStatus}
            style={{ minWidth: "200px" }}
            onChange={(_, newValue) => setSelectedDeliveryStatus(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Delivery Status"
                variant="outlined"
                style={{ minWidth: "150px" }}
                size="small"
              />
            )}
          />
          <Calendar
            variant="outlined"
            size="small"
            placeholder="Select Date"
            style={{ height: "2.5rem" }}
            value={searchDate}
            onChange={setSearchDate}
            range
            rangeHover
          />

          <Button
            variant="outlined"
            style={{ minWidth: "150px" }}
            type="primary"
            onClick={handleDownload}
            startIcon={!assignDriverLoader ? <Download /> : "" }
          >
            {!assignDriverLoader ? 'Download' : <CircularProgress size={18} style={{ color: 'blue' }}/>}
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "20px", overflowX: "auto" }}>
        <Paper elevation={3}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr No.</TableCell>
                  <TableCell align="center">Order Id</TableCell>
                  <TableCell align="center">Delivery Status</TableCell>
                  <TableCell align="center">Customer Name</TableCell>
                  <TableCell align="center">Chemist Name</TableCell>
                  <TableCell align="center">Driver</TableCell>
                  <TableCell align="center">Chemist Pay</TableCell>
                  <TableCell align="center">Driver Payout</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Scheduled Date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                  <TableCell align="center">Complete Order</TableCell>
                </TableRow>
              </TableHead>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "80%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              {!isLoading && (
                <TableBody>
                  {flattenedData.map((row) => (
                    <TableRow key={row?._id}>
                      <TableCell>{row?.srNo}</TableCell>
                      <TableCell>{row?.orderId}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: `${getStatusColor(row?.deliveryStatus)}`,
                        }}
                      >
                        {getStatusText(row?.deliveryStatus)}
                      </TableCell>
                      <TableCell>{row?.customer?.name}</TableCell>
                      <TableCell>{row?.chemist?.name}</TableCell>

                      <TableCell>
                        {row?.driver ? (
                          <Button
                            sx={{ width: "8rem", overflowX: "auto" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDriverModal(row);
                            }}
                          >
                            {row?.driver?.name}
                          </Button>
                        ) : row?.deliveryStatus === 0 ? (
                          <Button
                            style={{ border: "none" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDriverModal(row);
                            }}
                          >
                            + Assign Driver
                          </Button>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {row?.chemistPay ? row?.chemistPay : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row?.driverPayout ? row?.driverPayout : "N/A"}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <DatePicker
                          style={{ width: "90px" }}
                          value={row?.date}
                          onChange={(date) => handleDateChange(date, row?._id)}
                        />
                      </TableCell>
                      <TableCell>
                        {row?.scheduleDelivery
                          ? moment(row?.scheduleDelivery).format("YYYY/MM/DD")
                          : "Not Yet"}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          <Button
                            variant="outlined"
                            style={{ border: "none" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDetailsModal(row);
                            }}
                          >
                            <VisibilityIcon />
                          </Button>

                          <Button
                            variant="outlined"
                            style={{ border: "none" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteOrder(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={!row?.driver || row?.deliveryStatus === 3}
                          defaultChecked={row?.deliveryStatus === 3}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            markDeliverOrder(row?._id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalResult}
              page={page}
              backIconButtonProps={{
                disabled: page === 1,
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return `${getStartIndex()}-${getEndIndex()} of ${totalResult}`;
              }}
              nextIconButtonProps={{
                disabled: page * rowsPerPage >= totalResult,
              }}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </div>
      <Dialog
        open={isProductModalOpen}
        onClose={handleCloseProductModal}
        fullWidth
        maxWidth="md"
        style={{
          overflow: "auto",
          ...(window.innerWidth >= 768 && { marginLeft: 250 }),
        }}
      >
        <div
          style={{
            ...(window.innerWidth >= 768
              ? { position: "absolute", top: "20px", right: "20px" }
              : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }),
          }}
        >
          {" "}
          <div
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              width: "14rem",
            }}
          >
            <QRCode
              value={JSON.stringify({ id: selectedOrderDetails?._id }, null, 5)}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {selectedOrderDetails?.sign ? (
              <img
                style={{ borderRadius: "3px", height: "100px", width: "120px" }}
                src={selectedOrderDetails?.sign}
                alt="signature image"
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <DialogTitle style={{ textDecoration: "underline", fontStyle: "bold" }}>
          Order Details
        </DialogTitle>
        <DialogContent>
          {selectedOrderDetails && (
            <ListContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h2 style={{ marginTop: "10px", marginBottom: "10px" }}>
                    Customer Details:
                  </h2>
                  <p>Name: {selectedOrderDetails?.customer?.name}</p>
                  <p>Phone: {selectedOrderDetails?.customer?.phoneNo}</p>
                  <p>
                    Address:{" "}
                    {selectedOrderDetails?.customer?.streetAddress +
                      ", " +
                      selectedOrderDetails?.customer?.province +
                      ", " +
                      selectedOrderDetails?.customer?.city +
                      " - " +
                      selectedOrderDetails?.customer?.postalCode}
                  </p>

                  <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                    Chemist Details:
                  </h2>
                  <p>Name: {selectedOrderDetails?.chemist?.name}</p>
                  <p>Phone: {selectedOrderDetails?.chemist?.phoneNo}</p>
                  <p>
                    Address:{" "}
                    {selectedOrderDetails?.chemist?.streetAddress +
                      ", " +
                      selectedOrderDetails?.chemist?.province +
                      ", " +
                      selectedOrderDetails?.chemist?.city +
                      " - " +
                      selectedOrderDetails?.chemist?.postalCode}
                  </p>

                  {selectedOrderDetails?.driver && (
                    <div>
                      <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                        Driver Details:
                      </h2>
                      <p>Name: {selectedOrderDetails?.driver?.name}</p>
                      <p>Phone: {selectedOrderDetails?.driver?.phoneNo}</p>
                      <p>
                        Address:{" "}
                        {selectedOrderDetails?.driver?.streetAddress +
                          ", " +
                          selectedOrderDetails?.driver?.province +
                          ", " +
                          selectedOrderDetails?.driver?.city +
                          " - " +
                          selectedOrderDetails?.driver?.postalCode}
                      </p>
                    </div>
                  )}
                  <div>
                    <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                      Chemist Notes:
                    </h2>
                    <div>{selectedOrderDetails?.notes}</div>
                  </div>

                  <div>
                    <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                      Driver Notes:
                    </h2>
                    <div>{selectedOrderDetails?.driverNotes}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                ></div>
              </div>

              <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                Product Details:
              </h2>
              <div style={{ maxHeight: 300, width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Medication</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Payble Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedOrderDetails.product.map((product, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{product?.noOfPersons}</TableCell>
                          <TableCell>{product?.quantity}</TableCell>
                          <TableCell>{product?.price}</TableCell>
                          <TableCell>
                            {selectedOrderDetails?.paybleAmount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </ListContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{ padding: "10px" }} onClick={handleCloseProductModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDriverModalOpen}
        onClose={handleCloseDriverModal}
        fullWidth
        maxWidth="md"
        style={{
          margin: "auto",
          width: isLargeScreen ? "40%" : isMediumScreen ? "80%" : "90%",
        }}
      >
        <DialogTitle>Assign Driver & Apply Charges</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <h3>Customer Address : </h3>
                <Typography>
                  {selectedOrderDetails?.customer?.streetAddress +
                    ", " +
                    selectedOrderDetails?.customer?.province +
                    ", " +
                    selectedOrderDetails?.customer?.city +
                    " - " +
                    selectedOrderDetails?.customer?.postalCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>Chemist Address : </h3>
                <Typography>
                  {selectedOrderDetails?.chemist?.streetAddress +
                    ", " +
                    selectedOrderDetails?.chemist?.province +
                    ", " +
                    selectedOrderDetails?.chemist?.city +
                    " - " +
                    selectedOrderDetails?.chemist?.postalCode}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} marginTop={1.5}>
              <Autocomplete
                size="small"
                options={availableDrivers}
                getOptionLabel={(option) => option?.name || ""}
                value={selectedDriver}
                onChange={(event, newValue) => setSelectedDriver(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search and Select Driver"
                    required
                  />
                )}
              />
            </Grid>
            <Grid container spacing={1} marginTop={0.3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  size="small"
                  style={{ width: "100%" }}
                  id="chemistPay"
                  label="Chemist Pay"
                  placeholder="Chemist Pay"
                  onChange={(e) => setChemistPay(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  size="small"
                  style={{ width: "100%" }}
                  id="driverPayout"
                  label="Driver Payout"
                  placeholder="Driver Payout"
                  onChange={(e) => setdriverPayout(e.target.value)}
                />
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                style={{ padding: "10px" }}
                onClick={handleAssignDriver}
                type="submit"
                disabled={assignDriverLoader}
              >
                {!assignDriverLoader ? "Apply" : <CircularProgress size={18} style={{ color: 'white' }} />}
              </Button>
              <Button
                style={{ padding: "10px" }}
                onClick={handleCloseDriverModal}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default Order;
