// components
import Order from "@widgets/Orders";

const AdminOrderDashboard = () => {
  return (
    <>
      <Order />
    </>
  );
};

export default AdminOrderDashboard;
