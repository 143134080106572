// styled components
import { SettingsHeader } from "@widgets/UserSettings/style";
import { Divider } from "@components/Widget/style";

// components
import Widget from "@components/Widget";
import WidgetBody from "@components/Widget/WidgetBody";
import { Tab } from "react-bootstrap";
import Form from "./Form";
import Swal from "sweetalert2";
import { AuthContext } from "@contexts/index";
import { useContext } from "react";
import { Button } from "@mui/material";

const UserSettings = () => {
  const auth = useContext(AuthContext);

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to Logout?",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        auth.signout(() => {
          window.location.href = "/login";
        });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  return (
    <Widget name="UserSettings">
      <Tab.Container defaultActiveKey="patient" transition={true}>
        <SettingsHeader>
          <div className="wrapper">
            <h2 className="title">Your Profile</h2>
            <Button
              onClick={() => {
                handleLogout();
              }}
              style={{
                borderRadius: "5px",
                cursor: "pointer",
                color: "white",
                width: window.innerWidth <= 768 ? "100%" : "180px",
                backgroundColor: "#1ecdd4",
                fontWeight: "bold",
                marginRight: window.innerWidth <= 768 ? "0" : "10px",
              }}
            >
              <i
                className="icon icon-circle-user"
                style={{ marginRight: "8px" }}
              />{" "}
              Logout
            </Button>
          </div>
          <Divider />
        </SettingsHeader>
        <WidgetBody>
          <Tab.Content>
            <Tab.Pane eventKey="patient">
              <Form type="patient" />
            </Tab.Pane>
            <Tab.Pane eventKey="doctor">
              <Form type="doctor" />
            </Tab.Pane>
          </Tab.Content>
        </WidgetBody>
      </Tab.Container>
    </Widget>
  );
};

export default UserSettings;
