// styled components
import { Card, Info, Content } from "./style";

// components
import Widget from "@components/Widget";
import WidgetBody from "@components/Widget/WidgetBody";
import CountUp from "react-countup";

// SVG components
import { ReactComponent as UserSvg } from "../../assets/people_3239045.svg";
import { ReactComponent as ChemistSvg } from "../../assets/scientist_4247409.svg";
import { ReactComponent as DriverSvg } from "../../assets/taxi-driver_2365094.svg";

const Statistics = ({ data }) => {
  const Icon = () => {
    const svgStyles = {
      width: "60px",
      height: "60px",
      fill: "white",
    };
    switch (data.type) {
      default:
      case "cause":
        return <UserSvg style={svgStyles} />;
      case "teeth":
        return <ChemistSvg style={svgStyles} />;
      case "heart":
        return <DriverSvg style={svgStyles} />;
    }
  };

  const color = () => {
    switch (data.type) {
      default:
      case "cause":
        return "blue";
      case "teeth":
        return "teal";
      case "heart":
        return "green";
    }
  };

  return (
    <Widget name="Statistics">
      <WidgetBody>
        <Content>
          <Card className={color()}>
            <Icon />
          </Card>
          <Info>
            <CountUp
              className="value"
              start={0}
              end={data.value}
              delay={0}
              duration={1}
              enableScrollSpy
            />
            <p className="h3">{data.text}</p>
          </Info>
        </Content>
      </WidgetBody>
    </Widget>
  );
};

export default Statistics;
