// assets
import logo from "@assets/logo.png";
import logo2 from "@assets/logo2.png"

// styling
import styled from "styled-components/macro";
import { colors, fonts, textSizes, breakpoints } from "@styles/vars";
import theme from "styled-theming";

// components
import { NavLink } from "react-router-dom";

// utils
import PropTypes from "prop-types";

const Img = styled.img`
  width: 100%;
  will-change: transform;
  transition: transform var(--transition);
`;
const Imgm = styled.img`
  width: 40px;
  height: 40px;
  
  will-change: transform;
  transition: transform var(--transition);
`;

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  &:hover,
  &:focus {
    Img {
      transform: scale(1.2);
    }
  }
`;

const Text = styled.span`
  font-weight: 700;
  font-family: ${fonts.accent};
  font-size: ${textSizes["24"]};

  .highlight {
    color: ${theme("theme", {
  light: colors.blue,
  dark: "inherit",
})};
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes["32"]};
    Img {
      width: 50px; 
      margin-top: -15px !important;
    }
    @media screen and (max-width: 1024px) and (min-width: 600px) {
      Img {
        width: 50px !important; 
        margin-top: -15px !important;
      }
    }
  }
  ${breakpoints.mobileM} {
    font-size: ${textSizes["32"]};
    Img {
      width: 50px; 
    }
    @media screen and (max-width: 1024px) and (min-width: 600px) {
      Img {
        width: 50px !important; 
        margin-top: -15px !important;
      }
    }
  }
  ${breakpoints.mobileS} {
    font-size: ${textSizes["32"]};
    Img {
      width: 100px; 
    }
    @media screen and (max-width: 1024px) and (min-width: 600px) {
      Img {
        width: 50px !important; 
        margin-top: -15px !important;
      }
    }
  }
`;

const Logo = ({ compact }) => {
  return (
    <Wrapper as={NavLink} to="/dashboard" className="logo" style={{ margin: 'auto', }}>
      {/* <Img src={logo} alt="Secure Delivery" /> */}
      {!compact ? (
        <Text>
          <Img src={logo} alt="Secure Delivery" />
        </Text>
      ) : <Imgm src={logo2} alt="Secure Delivery" />}
    </Wrapper>
  );
};


Logo.propTypes = {
  compact: PropTypes.bool,

};

export default Logo;
