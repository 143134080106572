import React, { useState } from "react";
import styled from "styled-components/macro";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCustomer from "@widgets/EditCustomer";
import Widget from "@components/Widget";

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DoctorsList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "firstName", headerName: "Name", width: 200 },
    { field: "phoneno", headerName: "Phone No", type: "string", width: 200 },
    { field: "email", headerName: "Email", type: "string", width: 200 },
    { field: "address", headerName: "Address", type: "string", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div>
          <IconButton color="primary" onClick={() => handleEditRow(params.row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary">
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = [
    { id: 1, firstName: "Jon" },
    {
      id: 2,
      firstName: "Cersei",
      phoneno: 8168009530,
      email: "jon@gmail.com",
      address: "New Delhi",
    },
    { id: 3, firstName: "Jaime" },
    { id: 4, firstName: "Arya" },
    { id: 5, firstName: "Daenerys" },
    { id: 6, firstName: null },
    { id: 7, firstName: "Ferrara" },
    { id: 8, firstName: "Rossini" },
    { id: 9, firstName: "Harvey" },
  ];

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleEditRow = (id) => {
    setIsEditFormOpen(true);
    setSelectedRowId(id);
  };

  const closeEditForm = () => {
    setIsEditFormOpen(false);
    setSelectedRowId(null);
  };

  const handleSaveEditForm = (editedData) => {
    closeEditForm();
  };

  return (
    <ListContainer>
      <Widget name="Chemist">
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            disableSelectionOnClick 
            onRowClick={(params, event) => {
              const isEditIconClick =
                event.target.tagName === "svg" && event.target.parentNode.tagName === "button";
          
              if (isEditIconClick) {
                event.stopPropagation();
                handleEditRow(params.id); 
              }
            }}
          />
        </div>
      </Widget>

      {isEditFormOpen && (
        <EditCustomer
        isOpen={isEditFormOpen}  
        onClose={closeEditForm}
        onSave={handleSaveEditForm}
        rowId={selectedRowId}
        />
      )}
    </ListContainer>
  );
};

export default DoctorsList;
