import { useEffect, useState } from "react";
import { AuthContext } from "..";
import {
  apiPOST, apiPUT,
  // apiPUT
} from "../../utils/apiHelper";
import { useToast } from "@chakra-ui/react";
function AuthProvider({ children }) {
  const toast = useToast();

  let [user, setUser] = useState(null);
  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    if (userObj) {
      setUser(userObj);
    }
  }, []);

  let signin = async (userDetails, callback) => {
    let payload = userDetails;
    let response = await apiPOST("/v1/auth/login", payload);
    if (response.status === 200) {
      window.localStorage.setItem(
        "accesstoken",
        response.data.data.tokens.access.token
      );
      window.localStorage.setItem(
        "refreshToken",
        response.data.data.tokens.access.token
      );
      window.localStorage.setItem(
        "user",
        JSON.stringify(response.data.data.user)
      );
      setUser(response.data.data.user);
      callback({
        type: "success",
      });
    } else {
      callback({ type: response.data });
    }
  };

  let signout = (callback) => {
    setUser(null);
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("accesstoken");
    window.localStorage.removeItem("refreshToken");
    callback();
  };

  let updateUser = async (userDetails, callback) => {
    let payload = userDetails;
    const loggedInUserResponse = await apiPUT(
      `/v1/auth/updateUser`,
      payload
    );
    if (loggedInUserResponse.status === 201) {
      window.localStorage.setItem(
        "user",
        JSON.stringify(loggedInUserResponse.data.data.data)
      );
      setUser(loggedInUserResponse.data.data.data);
      callback({
        type: "success",
      });
    } else {
      callback({ type: loggedInUserResponse.data.message });
    }
  };

  let value = { user, signin, signout, updateUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
