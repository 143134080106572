// styled components
import { ListItem, Wrapper, Footer, Date } from "./style";

// components
import IconLink from "@ui/IconLink";
import MenuDots from "@ui/MenuDots";

import { useState } from "react";

import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import { apiGET, apiPUT } from "@utils/apiHelper";
import Swal from "sweetalert2";
import { useToast } from "@chakra-ui/react";

const PaymentItem = ({ data, className, getUserData }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [showPreviousPassword, setShowPreviousPassword] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const toast = useToast()
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [usersData, setUsersData] = useState({
    name: "",
    email: "",
    streetAddress: "",
    pincode: "",
    city: "",
    province: "",
    phoneNo: "",
  });

  const handleOpenAddUserModal = (item) => {
    getUserById(item?.id);
    setAddUserModalOpen(true);
  };

  const handleCloseAddUserModal = () => {
    setAddUserModalOpen(false);
    setUsersData({
      name: "",
      email: "",
      streetAddress: "",
      pincode: "",
      city: "",
      province: "",
      phoneNo: "",
    });
  };

  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  const getUserById = async (id) => {
    try {
      const response = await apiGET(`/v1/admin/getUserById/${id}`);
      if (response.status === 200) {
        setUsersData(response?.data?.data?.data);
      } else {
        toast({
          title: "Error Fetching User",
          description: response?.data,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error Fetching User",
        description: error?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateUser = async () => {
    try {
      const response = await apiPUT(
        `/v1/admin/updateUser/${usersData.id}`,
        usersData
      );
      if (response.status === 200) {
        getUserData();
        Swal.fire({
          icon: "success",
          title: "User Updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        handleCloseAddUserModal();
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response?.data?.error || "An error occurred while updating user",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating user",
        icon: "error",
      });
    }
  };

  const changePassword = async () => {
    try {
      const response = await apiPUT(
        `/v1/admin/changePassword/${usersData.id}`,
        { newPassword }
      );
      if (response.status === 200) {
        getUserData();
        Swal.fire({
          icon: "success",
          title: "Password Changed Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        handleCloseChangePasswordModal();
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response?.data?.error || "An error occurred while updating user",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating user",
        icon: "error",
      });
    }
  };

  const deleteUser = async () => {
    try {
      const response = await apiPUT(`/v1/admin/deleteUser/${usersData.id}`);
      if (response.status === 200) {
        getUserData();
        Swal.fire({
          icon: "success",
          title: "User Deleted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        handleCloseAddUserModal();
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response?.data?.error || "An error occurred while updating user",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while updating user",
        icon: "error",
      });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    await updateUser();
  };

  const handlePasswordFormSubmit = async (e) => {
    e.preventDefault();
    await changePassword();
  };

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    await deleteUser();
  };

  return (
    <ListItem className={`list-item ${className ? className : ""}`}>
      <Wrapper>
        <div className="main">
          <IconLink title={` ${capitalizeFirstLetter(data?.role)}`} />
          <span className="title">{data?.name}</span>
          <Footer>
        <Date>
          🏠{" "}
          <span className="date">
            {data?.streetAddress},{data?.pincode}
          </span>
        </Date>
        <MenuDots />
      </Footer>
        </div>
        <span className="payment">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "",
            }}
          >
            <Grid item>
              <Button
                variant="primary"
                onClick={() => handleOpenAddUserModal(data)}
                style={{
                  color: "white",
                  backgroundColor: "#1ecdd4",
                  maxWidth: "15px ",
                }}
              >
                <EditIcon />
              </Button>
            </Grid>
          </div>
          <div style={{marginTop:"8px"}}><span className="icon">📞 </span> {`+1 ${data?.phoneNo}`}</div>
        </span>
      </Wrapper>
     

      <Dialog
        open={isAddUserModalOpen}
        onClose={handleCloseAddUserModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Name"
                  fullWidth
                  size="small"
                  required
                  value={usersData?.name || ""}
                  onChange={(e) => {
                    setUsersData({ ...usersData, name: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  fullWidth
                  size="small"
                  required
                  value={usersData?.email || ""}
                  onChange={(e) => {
                    setUsersData({ ...usersData, email: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  required
                  size="small"
                  value={usersData?.phoneNo || ""}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, "");
                    let formattedValue = "";

                    for (let i = 0; i < value.length && i < 10; i++) {
                      if (i === 3 || i === 6) {
                        formattedValue += "-";
                      }
                      formattedValue += value[i];
                    }

                    setUsersData({ ...usersData, phoneNo: formattedValue });
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Street Address"
                  fullWidth
                  required
                  size="small"
                  value={usersData?.streetAddress || ""}
                  onChange={(e) => {
                    setUsersData({
                      ...usersData,
                      streetAddress: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  fullWidth
                  size="small"
                  required
                  value={usersData?.city || ""}
                  onChange={(e) => {
                    setUsersData({ ...usersData, city: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Province"
                  fullWidth
                  size="small"
                  required
                  value={usersData?.province || ""}
                  onChange={(e) => {
                    setUsersData({ ...usersData, province: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  fullWidth
                  size="small"
                  required
                  value={usersData?.postalCode || ""}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.toUpperCase();

                    value = value.replace(/[^A-Z0-9]/g, "");

                    value = value.replace(/(.{3})/g, "$1 ");

                    value = value.trim();

                    if (value.length <= 7) {
                      setUsersData({ ...usersData, postalCode: value });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  onClick={handleOpenChangePasswordModal}
                  style={{
                    color: "white",
                    backgroundColor: "#1ecdd4",
                    fontWeight: "bold",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                style={{ padding: "10px" }}
                onClick={handleCloseAddUserModal}
              >
                Cancel
              </Button>
              <Button
                style={{ padding: "10px" }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleFormSubmit}
              >
                Update User
              </Button>
              <Button
                style={{ padding: "10px" }}
                type="submit"
                variant="contained"
                onClick={handleDeleteUser}
              >
                Delete
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
        maxWidth="sm"
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <form onSubmit={handlePasswordFormSubmit}>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="New Password"
                  fullWidth
                  size="small"
                  required
                  type={showPreviousPassword ? "text" : "password"}
                  value={newPassword || ""}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                style={{ padding: "10px" }}
                onClick={handleCloseChangePasswordModal}
              >
                Cancel
              </Button>
              <Button
                style={{ padding: "10px" }}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save Changes
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </ListItem>
  );
};

export default PaymentItem;
