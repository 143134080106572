// styled components
import { Menu, UserWrapper } from "../style";
import Swal from "sweetalert2";
// components
import Avatar from "@ui/Avatar";

// utils
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";
import { AuthContext } from "@contexts/index";
// assets
import doc1jpg from "@assets/avatars/doc1.jpg";
import doc1webp from "@assets/avatars/doc1.jpg?as=webp";
import { useContext } from "react";

const CurrentUser = () => {
  const [open, setOpen] = useState(false);
  const handleClickAway = () => setOpen(false);
  // const handleClick = () => setOpen(!open);
  const auth = useContext(AuthContext);
  const src = {
    jpg: doc1jpg,
    webp: doc1webp,
  };

  const avatarUrl = auth?.user?.profilePic
    ? `data:image/jpeg;base64,${auth.user.profilePic}` 
    : src.jpg;

  // const handleLogout = () => {
  //   Swal.fire({
  //     title: "Do you want to Logout?",

  //     showCancelButton: true,
  //     confirmButtonText: "Logout",
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       auth.signout(() => {
  //         window.location.reload();
  //       });
  //     } else if (result.isDenied) {
  //       return;
  //     }
  //   });
  // };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <UserWrapper>
        <Avatar avatar={avatarUrl} alt="avatar" />
        <div className="info">
          <span className="h3">{auth?.user?.name.toUpperCase()}</span>
          <span className="position">{auth?.user?.role}</span>
          {/* <Menu className={open ? "visible" : ""}>
            <button
              onClick={() => {
                handleLogout();
              }}
            >
              <i className="icon icon-circle-user" /> Logout
            </button>
          </Menu> */}
        </div>
        {/* <button
          className="trigger"
          onClick={handleClick}
          aria-label="Show menu"
        >
          <i className="icon icon-chevron-down" />
        </button> */}
      </UserWrapper>
    </ClickAwayListener>
  );
};

export default CurrentUser;
