import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Toast, useToast } from "@chakra-ui/react";

const EditCustomer = ({ isOpen, onClose, rowId, onSave }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await fetch(`your-api-endpoint/${rowId}`);
        const customerData = await response.json();

        setFormData({
          id: customerData.id,
          name: customerData.name || "",
          phone: customerData.phone || "",
          email: customerData.email || "",
          address: customerData.address || "",
        });
      } catch (error) {
        toast({
          title: "Order not found!!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    if (isOpen && rowId !== null) {
      fetchCustomerData();
    }
  }, [isOpen, rowId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      await onSave(formData);
      toast({
        title: "Update successful!!",
        duration: 3000,
        status: "success",
        isClosable: true,
        position: "bottom-center",
      });
    } catch (error) {
      toast({
        title: "Error updating customer!!",
        duration: 3000,
        status: "danger",
        isClosable: true,
        position: "bottom-center",
      });
    }

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Customer</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone No"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomer;
