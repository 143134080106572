// import React from "react";
import Page from "@layout/Page";
import QRCode from "react-qr-code";
import React, { useState, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "react-multi-date-picker";
import {
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Paper,
  FormControlLabel,
  Autocomplete,
  Box,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { apiGET, apiPOST, apiPUT } from "@utils/apiHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import moment from "moment";
import { preventDefault } from "@utils/helpers";
import { Container } from "react-bootstrap";
import { height } from "@mui/system";
import { disabled } from "@db/calendar_appointments";
import Calendar from "react-multi-date-picker";

const ChemistDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);
  const [productsModalContent, setProductsModalContent] = useState([]);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [selectedId, setSelectedId] = useState(null);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState();

  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [showError, setShowError] = useState(false);
  let qrCodeRef = React.createRef();

  const [searchCustomerName, setSearchCustomerName] = useState();
  const [searchDriverName, setSearchDriverName] = useState();
  const [searchOrderId, setSearchOrderId] = useState();
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState();
  const [searchDate, setSearchDate] = useState([]);
  const [destructuredSearchDate, setDestructuredSearchedDate] = useState({});

  const handleSearchDate = () => {
    if (searchDate?.length == 2) {
      const startDate = `${searchDate[0]?.year}/${String(
        searchDate[0].day
      ).padStart(2, "0")}/${String(searchDate[0]?.monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      const endDate = `${searchDate[1]?.year}/${String(
        searchDate[1]?.day
      ).padStart(2, "0")}/${String(searchDate[1]?.monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      setDestructuredSearchedDate({ fromDate: startDate, toDate: endDate });
    }
  };


  useEffect(() => {
    handleSearchDate();
  }, [searchDate]);


  const deliveryStatusOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Delivery Assigned" },
    { value: 2, label: "Picked By Driver" },
    { value: 3, label: "Delivered" },
    { value: 4, label: "Failed" },
    { value: 5, label: "Return" },
  ];
  const getQueryString = (params) => {
    return Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  };

  function svgToDataURL(svgElement) {
    let svgString = new XMLSerializer().serializeToString(svgElement);
    return "data:image/svg+xml;base64," + btoa(svgString);
  }

  const downloadQRCode = () => {
    let canvas = qrCodeRef.current;
    if (canvas) {
      let url = svgToDataURL(canvas);
      let img = new Image();
      img.onload = function () {
        let tempCanvas = document.createElement("canvas");
        tempCanvas.width = img.width;
        tempCanvas.height = img.height;
        let ctx = tempCanvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        url = tempCanvas.toDataURL("image/jpeg");
  
        // Print the QR code
        printImage(url);
      };
      img.src = url;
    }
  };
  
  const printImage = (imageUrl) => {
    // Create a hidden iframe
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  
    // Wait for the iframe to load
    iframe.onload = function() {
      let iframeWindow = iframe.contentWindow;
      if (iframeWindow) {
        // Write the image to the iframe document
        iframeWindow.document.write("<img src='" + imageUrl + "'/>");
        iframeWindow.document.close();
  
        // Trigger the print dialog
        iframeWindow.print();
  
        // Remove the iframe after printing
        setTimeout(function() {
          document.body.removeChild(iframe);
        }, 1000); // Adjust the delay as needed
      } else {
        console.error("Failed to access iframe content window");
      }
    };
  
    // Set the iframe source to about:blank
    iframe.src = 'about:blank';
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event?.target.value, 10);
    setrowsPerPage(newSize);
    setPage(0);
  };

  const handleViewProducts = (orderDetails) => {
    setSelectedOrderDetails(orderDetails);
    setIsProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setIsProductModalOpen(false);
    setProductsModalContent([]);
  };

  const handleEditOrder = (orderId) => {
    const order = orders?.find((order) => order?._id === orderId);
    setSelectedId(orderId);
    if (order) {
      setFormData({
        customerName: order.customer.name,
        customerId: order.customer._id,
        phoneNo: order.customer.phoneNo,
        streetAddress: order.customer.streetAddress,
        province: order.customer.province,
        city: order.customer.city,
        postalCode: order.customer.postalCode.toString(),
        paybleAmount: order.paybleAmount,
        paymentStatus: order.paymentStatus.toString(),
        scheduleDelivery: order.scheduleDelivery,
        notes: order.notes,
        product:
          order.product != null
            ? order?.product?.map((product) => ({
              noOfPersons: product?.noOfPersons,
              quantity: product?.quantity.toString(),
              price: product?.price.toString(),
              refrigeratedMedications: product?.refrigeratedMedications,
            }))
            : "",
      });
      setModalMode("Edit");
      setIsModalOpen(true);
    } else {
      toast({
        title: "Error!",
        description: "Order not found!",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  };

  const handleSaveEdits = async () => {
    preventDefault();
    setLoading(true);
    try {
      const response = await apiPUT(
        `/v1/order/updateOrder/${selectedId}`,
        formData
      );

      if (response.status === 200) {
        handleCloseModal();
        getAllOrders();
        toast({
          title: "Order Updated Successfully!!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: `${response.data?.data}`,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const flattenedData = orders?.map((item, index) => ({
    _id: item._id,
    srNo: index + 1,
    orderId: item.orderId,
    customerName: item?.customer.name,
    phoneNo: item?.customer.phoneNo,
    customer: item?.customer,
    product: item?.product,
    streetAddress: `${item?.customer?.streetAddress}, ${item?.customer?.city}, ${item?.customer?.province}, ${item?.customer.postalCode}`,
    paybleAmount: item?.paybleAmount,
    paymentStatus: item?.paymentStatus,
    chemistPay: item?.chemistPay,
    customCreatedAt: item?.customCreatedAt,
    notes: item?.notes,
    driverNotes:item?.driverNotes,
    sign: item?.sign
  }));

  const displayedData = flattenedData

  const handleDeleteOrder = async (orderId) => {
    const result = await Swal.fire({
      title: "Delete Order?",
      text: "Are you sure you want to delete this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await apiPUT(`/v1/order/deleteOrder/${orderId}`);
        if (response.status === 200) {
          getAllOrders();
          Swal.fire("Deleted!", "The order has been deleted.", "success");
        }
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      }
    }
  };

  const [formData, setFormData] = useState({
    customerName: "",
    paymentStatus: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    province: "",
    totalAmount: "",
    paybleAmount: "",
    phoneNo: "",
  
    scheduleDelivery: "",
    notes: "",
    product: [
      {
        noOfPersons: 0,
        quantity: 0,
        price: 0,
        refrigeratedMedications: false,
      },
    ],
  });

  const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const handleOpenModal = () => {
    setModalMode("Add");
    setIsModalOpen(true);
    setShowError(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowError(false);
    setFormData({
      customerName: "",
      paymentStatus: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      province: "",
      totalAmount: "",
      paybleAmount: "",
      phoneNo: "",
      scheduleDelivery: "",
      notes: "",
      product: [
        {
          noOfPersons: 0,
          quantity: 0,
          price: 0,
          refrigeratedMedications: false,
        },
      ],
    });
  };

  const handlePostOrder = async () => {
    setLoading(true);
    setFormData({...formData,  customCreatedAt:Date.now(),});
    try {
      const response = await apiPOST("/v1/order/addOrder", formData);
      if (response.status === 200) {
        handleCloseModal();
        getAllOrders();
        toast({
          title: "Order Added Successfully!!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
    setShowError(true);
  };

  const getAllOrders = async () => {
    setLoading(true);
    const queryParams = {
      limit: rowsPerPage ? rowsPerPage : 10,
      page: page ? page : 1,
    };
    let filterQueryObject;

    if (selectedDeliveryStatus) {
      filterQueryObject = {
        ...filterQueryObject,
        deliveryStatus: selectedDeliveryStatus.value,
      };
    }
    if (searchOrderId) {
      filterQueryObject = { ...filterQueryObject, orderId: searchOrderId };
    }
    if (searchDriverName) {
      filterQueryObject = {
        ...filterQueryObject,
        driverName: searchDriverName,
      };
    }
    if (searchCustomerName) {
      filterQueryObject = {
        ...filterQueryObject,
        customerName: searchCustomerName,
      };
    }

    if (destructuredSearchDate?.fromDate && destructuredSearchDate.toDate) {
      filterQueryObject = {
        ...filterQueryObject,
        fromDate: destructuredSearchDate?.fromDate,
        toDate: destructuredSearchDate?.toDate,
      };
    }

    queryParams.filter = JSON.stringify(filterQueryObject);

    try {
      const response = await apiGET(
        `/v1/order/getAllOrderchemist?${getQueryString(queryParams)}`
      );


      if (response.status === 200) {
        setOrders(response?.data?.data?.data);
        setPage(response?.data?.data?.page);
        // setrowsPerPage(response?.data?.data?.limit);
        setTotalResult(response?.data?.data?.totalResults);
      }
    } catch (error) {
      toast({
        title: "Error!!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateTotalAndPayableAmount();
  }, [formData?.product]);

  useEffect(() => {
    getAllOrders();
  }, [
    searchCustomerName,
    searchOrderId,
    searchDriverName,
    page,
    selectedDeliveryStatus,
    destructuredSearchDate?.fromDate,
    destructuredSearchDate?.toDate,
  ]);

  const calculateTotalAndPayableAmount = () => {
    let totalAmount = 0;
    formData?.product.forEach((product) => {
      const quantity = parseFloat(product?.quantity) || 0;
      const price = parseFloat(product?.price) || 0;
      totalAmount += quantity * price;
    });

    const roundedTotalAmount = Math?.round(totalAmount * 100) / 100;

    setFormData((prevData) => ({
      ...prevData,
      totalAmount: roundedTotalAmount?.toFixed(2),
      paybleAmount: roundedTotalAmount?.toFixed(2),
    }));
  };

  const handleFormChange = (field, value, index) => {
    if (field === "product") {
      const updatedMedicines = [...formData.product];
      updatedMedicines[index] = { ...updatedMedicines[index], ...value };
      setFormData((prevData) => ({ ...prevData, product: updatedMedicines }));
      calculateTotalAndPayableAmount();
    } else {
      setFormData((prevData) => ({ ...prevData, [field]: value }));
    }
  };

  const getEndIndex = () => {
    const endIndex = page * rowsPerPage;
    return endIndex > totalResult ? totalResult : endIndex;
  };

  const getStartIndex = () => {
    return (page - 1) * rowsPerPage + 1;
  };

  const minimumDate = () => {
    let currentDate = new Date();
    let yesterday = new Date(currentDate);
    return yesterday.setDate(currentDate.getDate() - 1);
  }

  return (
    <Page title="">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item style={{ marginTop: "0px" }}>
          <h1>Dashboard</h1>
        </Grid>
        <Grid item>
          <Button
            onClick={handleOpenModal}
            style={{
              color: "white",
              backgroundColor: "#1ecdd4",
              fontWeight: "bold",
            }}
          >
            + Add Order
          </Button>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          paddingTop: "5px",
          marginTop: "20px",
          overflowX: "auto",
          minWidth: "320px",
        }}
      >
        <TextField
          label="Search Customer Name"
          variant="outlined"
          size="small"
          style={{ minWidth: "150px", maxWidth: "200px" }}
          value={searchCustomerName}
          onChange={(e) => setSearchCustomerName(e.target.value)}
        />
        <TextField
          label="Search Driver Name"
          variant="outlined"
          size="small"
          style={{ minWidth: "150px", maxWidth: "200px" }}
          value={searchDriverName}
          onChange={(e) => setSearchDriverName(e.target.value)}
        />

        <TextField
          label="Search Order Id"
          variant="outlined"
          size="small"
          style={{ minWidth: "150px", maxWidth: "200px" }}
          value={searchOrderId}
          onChange={(e) => setSearchOrderId(e.target.value)}
        />

        <Autocomplete
          options={deliveryStatusOptions}
          getOptionLabel={(option) => option.label}
          value={selectedDeliveryStatus}
          style={{ minWidth: "150px" }}
          onChange={(_, newValue) => setSelectedDeliveryStatus(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Delivery Status"
              variant="outlined"
              size="small"
              style={{ minWidth: "150px", maxWidth: "200px" }}
            />
          )}
        />

        <Calendar
          variant="outlined"
          size="small"
          placeholder="Select Date"
          style={{
            height: "38px",
            minWidth: "150px",
            maxWidth: "200px",
          }}
          value={searchDate}  
          onChange={setSearchDate}
          range
          rangeHover
        />
      </div>

      <div style={{ marginTop: "5px", overflowX: "auto", maxHeight: "69vh" }}>
        <Paper elevation={3}>
          <TableContainer
            style={{
              height: "auto",
              width: "100%",
              overflow: "auto",
              marginTop: "10px",
            }}
          >
            <Table
              style={{
                height: "auto",
                width: "100%",
                overflowY: "auto",
                maxWidth: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Sr No.</TableCell>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Payable Amount</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Pay</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {displayedData &&
                    displayedData?.map((row) => (
                      <TableRow key={row?._id}>
                        <TableCell>{row?.srNo}</TableCell>
                        <TableCell>{row?.orderId}</TableCell>
                        <TableCell>{row?.customerName}</TableCell>
                        <TableCell>{row?.phoneNo}</TableCell>
                        <TableCell>{row?.paybleAmount}</TableCell>
                        <TableCell>
                          {row?.paymentStatus === 1
                            ? "COD"
                            : row?.paymentStatus === 0
                              ? "Prepaid"
                              : ""}
                        </TableCell>
                        <TableCell>{row.chemistPay ?? "N/A"}</TableCell>
                        <TableCell>
                          {moment(row?.customCreatedAt).format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell style={{ width: "8rem" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            <Button
                              title="View Products"
                              variant="outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleViewProducts(row);
                              }}
                              style={{
                                border: "none",
                                fontSize: "0.8rem",
                                width: "10px",
                              }}
                            >
                              <VisibilityIcon />
                            </Button>
                            <Button
                              title="Edit Order"
                              variant="outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditOrder(row?._id);
                              }}
                              style={{
                                border: "none",
                                fontSize: "0.8rem",
                                width: "2rem",
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              title="Delete Order"
                              variant="outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteOrder(row?._id);
                              }}
                              style={{
                                border: "none",
                                fontSize: "0.8rem",
                                width: "2rem",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              component="div"
              count={totalResult}
              page={page}
              backIconButtonProps={{
                disabled: page === 1,
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return `${getStartIndex()}-${getEndIndex()} of ${totalResult}`;
              }}
              nextIconButtonProps={{
                disabled: page * rowsPerPage >= totalResult,
              }}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          {loading && <CircularProgress />}
        </div>
      </div>
      {/* ADD ORDER */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        style={{
          overflow: "auto",
          ...(window.innerWidth >= 768 && { marginLeft: 250 }),
        }}
      >
        <Box component="form" noValidate autoComplete="off">
          <DialogTitle style={{ marginTop: "10px" }}>
            {" "}
            {modalMode === "Add" ? "Add Order" : "Edit Order"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: "12px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formData?.customerName === "" && showError}
                  helperText={
                    formData?.customerName === "" &&
                    showError &&
                    "This field is Required"
                  }
                  label="Customer Name"
                  fullWidth
                  size="small"
                  value={formData?.customerName}
                  onChange={(e) =>
                    handleFormChange("customerName", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="text"
                  error={formData?.phoneNo === "" && showError}
                  helperText={
                    formData?.phoneNo === "" &&
                    showError &&
                    "This field is Required"
                  }
                  label="Phone Number"
                  fullWidth
                  size="small"
                  value={formData?.phoneNo}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, "");
                    let formattedValue = "";
                    for (let i = 0; i < value.length && i < 10; i++) {
                      if (i === 3 || i === 6) {
                        formattedValue += "-";
                      }
                      formattedValue += value[i];
                    }
                    handleFormChange("phoneNo", formattedValue);
                  }}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formData?.streetAddress === "" && showError}
                  helperText={
                    formData?.streetAddress === "" &&
                    showError &&
                    "This field is Required"
                  }
                  label="StreetAddress"
                  fullWidth
                  size="small"
                  value={formData?.streetAddress}
                  onChange={(e) =>
                    handleFormChange("streetAddress", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formData?.city === "" && showError}
                  helperText={
                    formData?.city === "" &&
                    showError &&
                    "This field is Required"
                  }
                  label="City"
                  fullWidth
                  size="small"
                  value={formData?.city}
                  onChange={(e) => handleFormChange("city", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={formData?.province === "" && showError}
                  helperText={
                    formData?.province === "" &&
                    showError &&
                    "This field is Required"
                  }
                  label="Province"
                  fullWidth
                  size="small"
                  value={formData?.province}
                  onChange={(e) => handleFormChange("province", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  fullWidth
                  size="small"
                  value={formData?.postalCode || ""}
                  error={formData?.postalCode === "" && showError}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.toUpperCase();
                    value = value.replace(/[^A-Z0-9]/g, "");
                    value = value.replace(/(.{3})/g, "$1 ");
                    value = value.trim();
                    if (value.length <= 7) {
                      handleFormChange("postalCode", value);
                    }
                  }}
                  helperText={
                    formData?.postalCode === "" &&
                    showError &&
                    "This field is Required"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Total Amount"
                  fullWidth
                  size="small"
                  disabled
                  value={formData?.totalAmount}
                  onChange={(e) =>
                    handleFormChange("totalAmount", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Payable Amount"
                  fullWidth
                  size="small"
                  disabled
                  value={formData?.paybleAmount}
                  onChange={(e) =>
                    handleFormChange("paybleAmount", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography>Schedule Delivery</Typography>
                <Container
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "2.5rem",
                    borderRadius: "5px",
                  }}
                >
                  <DatePicker
                    style={{
                      border: "none",
                      height: "2.5rem",
                      borderRadius: "5px",
                    }}
                    fullWidth
                    minDate={minimumDate()}
                    value={formData?.scheduleDelivery}
                    onChange={(date) => {
                      if (date != null) {
                        const selectedDate = `${date.year
                          .toString()
                          .padStart(4, "0")}/${String(
                            date.month.number
                          ).padStart(2, "0")}/${String(date.day).padStart(
                            2,
                            "0"
                          )}`;
                        handleFormChange("scheduleDelivery", selectedDate);
                      }
                    }}
                  />
                </Container>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel>Payment Status</InputLabel>
                <Select
                  error={formData?.paymentStatus === "" && showError}
                  helperText={
                    formData?.paymentStatus === "" &&
                    showError &&
                    "This field is Required"
                  }
                  fullWidth
                  size="small"
                  value={formData?.paymentStatus}
                  onChange={(e) =>
                    handleFormChange("paymentStatus", e.target.value)
                  }
                >
                  <MenuItem value="1">Cash</MenuItem>
                  <MenuItem value="0">Prepaid</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {formData &&
              formData?.product?.map((product, index) => (
                <Grid
                  container
                  spacing={2}
                  key={`product-${index}`}
                  style={{ marginTop: "3px" }}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      type="number"
                      error={product?.noOfPersons === 0 && showError}
                      helperText={
                        product?.noOfPersons === 0 &&
                        showError &&
                        "This field is Required"
                      }
                      label="No. Of Medication"
                      fullWidth
                      size="small"
                      value={product?.noOfPersons}
                      onChange={(e) =>
                        handleFormChange(
                          "product",
                          { noOfPersons: e.target.value },
                          index
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={3.5} sm={2.5}>
                    <TextField
                      error={product?.quantity === 0 && showError}
                      helperText={
                        product?.quantity === 0 &&
                        showError &&
                        "This field is Required"
                      }
                      label="Quantity"
                      fullWidth
                      size="small"
                      defaultValue={product?.quantity}
                      value={product?.quantity}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        if (
                          regex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          handleFormChange(
                            "product",
                            { quantity: e.target.value },
                            index
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3.5} sm={2.5}>
                    <TextField
                      error={product?.price === 0 && showError}
                      helperText={
                        product?.price === 0 &&
                        showError &&
                        "This field is Required"
                      }
                      label="Price"
                      fullWidth
                      size="small"
                      defaultValue={product.price}
                      value={product?.price}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        if (
                          regex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          handleFormChange(
                            "product",
                            { price: e.target.value },
                            index
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3.5} sm={2.5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={product?.refrigeratedMedications}
                          onChange={(e) =>
                            handleFormChange(
                              "product",
                              { refrigeratedMedications: e.target.checked },
                              index
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Refrigerate"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Notes"
                      fullWidth
                      multiline
                      value={formData?.notes}
                      onChange={(e) =>
                        handleFormChange("notes", e.target.value)
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              ))}
          </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ padding: "10px" }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              modalMode === "Add" ? handlePostOrder() : handleSaveEdits();
            }}
            loading={loading}
            variant="contained"
            style={{
              backgroundColor: "rgb(38,98,240)",
              color: "white",
              padding: "10px",
            }}
          >
            {loading ? (
              <CircularProgress size={18} />
            ) : modalMode === "Add" ? (
              "+ Add Order"
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isProductModalOpen}
        onClose={handleCloseProductModal}
        fullWidth
        maxWidth="md"
        style={{
          overflow: "auto",
          ...(window.innerWidth >= 768 && { marginLeft: 250 }),
        }}
      >
        <div
          style={{
            ...(window.innerWidth >= 768
              ? { position: "absolute", top: "20px", right: "20px" }
              : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10px",
              }),
          }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <QRCode
              ref={qrCodeRef}
              value={JSON.stringify({ id: selectedOrderDetails?._id }, null, 5)}
              size={256}
              style={{ height: "25vh", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 256 256`}
            />

            <Button
              style={{
                marginTop: "10px",
              }}
              onClick={downloadQRCode}
              variant="contained"
            >
              Print Qr
            </Button>

            {selectedOrderDetails?.sign ? <img style={{ borderRadius: "3px", height: "50px", width: "120px", display: "block" }} src={selectedOrderDetails?.sign} alt="signature image" />
              : ""}
          </div>
        </div>
        <DialogTitle style={{ textDecoration: "underline", fontStyle: "bold" }}>
          Order Details
        </DialogTitle>
        <DialogContent>
          {selectedOrderDetails && (
            <ListContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h2 style={{ marginTop: "10px", marginBottom: "10px" }}>
                    Customer Details:
                  </h2>
                  <p>Name: {selectedOrderDetails?.customer?.name}</p>
                  <p>Phone: {selectedOrderDetails?.customer?.phoneNo}</p>
                  <p>
                    Street Address:{" "}
                    {selectedOrderDetails?.customer?.streetAddress +
                      ", " +
                      selectedOrderDetails?.customer?.province +
                      ", " +
                      selectedOrderDetails?.customer?.city +
                      " - " +
                      selectedOrderDetails?.customer?.postalCode}
                  </p>

                  {selectedOrderDetails?.driver && (
                    <div>
                      <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>
                        Driver Details:
                      </h2>
                      <p>Name: {selectedOrderDetails?.driver?.name}</p>
                      <p>Phone: {selectedOrderDetails?.driver?.phoneNo}</p>
                      <p>
                        Street Address:{" "}
                        {selectedOrderDetails?.driver?.streetAddress +
                          ", " +
                          selectedOrderDetails?.driver?.province +
                          ", " +
                          selectedOrderDetails?.driver?.city +
                          " - " +
                          selectedOrderDetails?.driver?.postalCode}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                </div>
              </div>

              <div
                style={{
                  marginTop: "40px",
                  width: window.innerWidth > 767 ? "75%" : "auto"
                }}>
                <h2> Chemist Notes :</h2>
                <p>{selectedOrderDetails?.notes}</p>
              </div>
              <div
                style={{
                  marginTop: "40px",
                  width: window.innerWidth > 767 ? "75%" : "auto"
                }}>
                <h2>Driver Notes :</h2>
                <p>{selectedOrderDetails?.driverNotes}</p>
              </div>
              <h2 style={{ marginTop: "40px", marginBottom: "10px" }}>
                Product Details:
              </h2>
              <div style={{ maxHeight: 300, width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Medicine Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Payble Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedOrderDetails &&
                        selectedOrderDetails?.product?.map((product, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{product?.noOfPersons}</TableCell>
                            <TableCell>{product?.quantity}</TableCell>
                            <TableCell>{product?.price}</TableCell>
                            <TableCell>{selectedOrderDetails?.paybleAmount}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </ListContainer>
          )}
        </DialogContent>

        <DialogActions>
          <Button style={{ padding: "10px" }} onClick={handleCloseProductModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
export default ChemistDashboard;
