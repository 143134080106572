import Page from "@layout/Page";

const Orders = () => {
  return(
   <Page title="Chemist Orders">
   </Page>
  )
};

export default Orders;
