import axios from "axios";
const token = "";
const baseUrl ="https://api.securedeliver.ca";
// const baseUrl ="http://localhost:3001";
const getAccessToken = () => {
  return localStorage.getItem("accesstoken");
};

const axiosApi = axios.create({
  baseURL: baseUrl,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function apiGET(url, config = {}) {
  let accessToken = getAccessToken();
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}

export async function apiDownload(url, config = {}) {
  let accessToken = getAccessToken();
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  axiosApi.defaults.headers.common["Content-Type"] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}
export async function apiPOST(url, data, config = {}) {
  let accessToken = getAccessToken();
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}

export async function apiPUT(url, data, config = {}) {
  let accessToken = getAccessToken();
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}

export async function apiDELETE(url, config = {}) {
  let accessToken = getAccessToken();
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response)
    .catch((error) => error.response);
}

export const objectToQueryParam = (obj) => {
  if (typeof obj !== "object") return "";

  let objKeys = Object.keys(obj);
  if (Object.keys(obj).length) {
    const queryParams = new URLSearchParams();
    objKeys.map((key) => {
      if (obj[key]) {
        queryParams.append(key, JSON.stringify(obj[key]));
      }
    });
    return decodeURIComponent(queryParams.toString());
  }

  return "";
};
