import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import style from "./Login.module.css";
import { useTheme } from "@mui/material/styles";
import { useToast } from "@chakra-ui/react";
import { AuthContext } from "../../contexts";
import { useContext } from "react";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const images = [
  {
    imgPath: "https://templates.iqonic.design/xray/html/images/login/3.png",
  },
  {
    imgPath: "https://templates.iqonic.design/xray/html/images/login/1.png",
  },
  {
    imgPath: "https://templates.iqonic.design/xray/html/images/login/2.png",
  },
];

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading,setIsLoading] = useState(false)
  let auth = useContext(AuthContext);
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  from = from === "/" ? "/dashboard" : from;
  useEffect(() => {
    let user = localStorage.getItem("accesstoken");
    if (user) {
      navigate('/dashboard');
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    try {
      const userDetails = {
        email: email,
        password: password,
      };
      auth.signin(userDetails, (type) => {
        if (type.type === "success") {
          toast({
            title: "Welcome!!",
            description: "Login successfully!!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setRedirecting(true);
        } else {
          setIsLoading(false)
          toast({
            title: "Login Failed.",
            description: `${type.type.data}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      });
      return;
    } catch (e) {
      toast({
        title: "Welcome!!",
        description: e.message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (redirecting) {
      const redirectTimer = setTimeout(() => {
        window.location.href = "/dashboard";
        setIsLoading(false)
      },100);
      return () => clearTimeout(redirectTimer);
    }
  }, [redirecting]);

  return (
    <div className={style.maindiv} >
      <div className={style.card1} >
        <div style={{width:'100%'}}>
          <div className={style.imgheader}>
            <img src={require("./final logo.jpg")} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              name="email"
              margin="dense"
              onChange={handleEmailChange}
              style={{
                // width: window.innerWidth <=500? "90%":"48%"
              }}
            />
            <TextField
              fullWidth
              type="password"
              margin="normal"
              label="Password"
              variant="outlined"
              value={password}
              required
              name="password"
              onChange={handlePasswordChange}
            />
            <button type="submit" className={style.btn}>
              {!isLoading ? "Sign in" : <CircularProgress sx={{color:"white"}} size={18} />}

            </button>
          </form>
        </div>
      </div>
      <div>
        <img
          className={style.loginImage}
          src={require("./loginImage1.jpg")}
          alt="error"
        />
      </div>
    </div>
  );
};

export default Login;
