import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid
} from "@material-ui/core";
import Calendar from "react-multi-date-picker";
import { apiGET } from "@utils/apiHelper";
import { Autocomplete } from "@mui/material";

function GenerateInvoice() {
  const [role, setRole] = useState("chemist");
  const [date, setDate] = useState(null);
  const [destructuredDate, setDestructuredDate] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [name, setName] = useState();

  const handledate = () => {
    if (date && date.length === 2) {
      const startDate = `${date[0].year}/${String(date[0].day).padStart(
        2,
        "0"
      )}/${String(date[0].monthIndex + 1).padStart(2, "0")}`;
      const endDate = `${date[1].year}/${String(date[1].day).padStart(
        2,
        "0"
      )}/${String(date[1].monthIndex + 1).padStart(2, "0")}`;
      setDestructuredDate({ fromDate: startDate, toDate: endDate });
    }
  };

  const getAllUsers = async () => {
    try {
      let reseponse;
      if (name) {
        reseponse = await apiGET(
          `/v1/admin/getAlluser?filter={role:${role},name:${name}}`
        );
      } else {
        reseponse = await apiGET(`/v1/admin/getAlluser?filter={role:${role}}`);
      }
      if (reseponse.status === 200) {
        setAllUsers(reseponse.data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handledate();
    getAllUsers();
  }, [role, date, name]);

  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width: window.innerWidth > 768 ? "30vw" : "auto" }}>

        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent style={{ padding: "20px" }}>
          <InputLabel style={{ marginBottom: "5px" }} id="role-label">
            Select Role
          </InputLabel>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginBottom: "1rem" }}
            size="small"
          >
            <Select
              labelId="role-label"
              id="role"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <MenuItem value={"chemist"}>Chemist</MenuItem>
              <MenuItem value={"driver"}>Driver</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{ marginBottom: "5px" }} id="user-label">
            Select User
          </InputLabel>

          <FormControl
            variant="outlined"
            style={{ width: "100%", marginBottom: "1rem"}}
            size="small"

          >
            <Autocomplete
              id="user"
              options={allUsers??[]}          
              getOptionLabel={(option) =>option.name}
              value={allUsers && allUsers.length > 0 ? allUsers.find(user => user.id === userId) : null}
              onChange={(event, newValue) => setUserId(newValue?.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  placeholder="Search & Select User"
                />
              )}
            />
          </FormControl>
          <Box
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <Calendar
            
              variant="outlined"
              placeholder="Select Date"
              style={{ height: "2.5rem" , width: window.innerWidth > 768 ? "190%" : "100%"  }}
              value={date}
              onChange={(value) => setDate(value)}
              range
              rangeHover
            />
          </Box>

          <DialogActions>
            <Button style={{ padding: "10px" }} onClick={null} type="submit">
              Generate Invoice
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    </Box>
  );
}

export default GenerateInvoice;
