import React, { useState, useEffect } from "react";
import { StyledForm, Container } from "@widgets/UserSettings/style";
import LabeledFormInput from "@ui/LabeledFormInput";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { apiGET, apiPUT } from "@utils/apiHelper";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { color } from "@mui/system";
import { preventDefault } from "@utils/helpers";
import Snackbar from "@mui/material/Snackbar";
import { AuthContext } from "@contexts/index";
import { useContext } from "react";
import { useToast } from "@chakra-ui/react";

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const Form = ({ type }) => {
  const hint = "Drag image here or click to select file";
  const [base64Image, setBase64Image] = useState("");
  let [userData, setUserData] = useState({});
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [previousPass, setPreviousPass] = useState();
  const [newpassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [showPreviousPassword, setShowPreviousPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPassLoader, setPassLoader] = useState(false);
  let auth = useContext(AuthContext);
  const [isPreviousPassError, setPreviousPassError] = useState(false);
  const [isNewPasswordError, setNewPasswordError] = useState(false);
  const [isConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [compressedImg,setCompressedImg] = useState();
  const toast = useToast()
  
  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setConfirmPassword("");
    setPreviousPass("");
    setNewPassword("");
    setChangePasswordModalOpen(false);

    // Reset error states
    setPreviousPassError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setErrorMessage("");
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file) {
        if (isImage(file)) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            setBase64Image(base64String);
          };
          reader.readAsDataURL(file);
        } else {
          Swal.fire({
            title: "Invalid file type",
            text: "Please select a valid image file (jpeg, png, jpg).",
            icon: "error",
          });
        }
      }
    }
  };

  const isImage = (file) => {
    return (
      file.type.includes("image/jpeg") ||
      file.type.includes("image/png") ||
      file.type.includes("image/jpg")
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const getCurrentUser = async () => {
    try {
      const response = await apiGET("/v1/auth/getCurrentUser");
      if (response.status) {
        setUserData(response?.data?.data?.userData);
        setBase64Image(response?.data?.data?.userData?.profilePic);
      } else {
        toast({
          title: "Error!",
          description: response?.data,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: error?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateUser = async (e) => {
    setIsLoading(true);
    preventDefault(e);
    userData = { ...userData, profilePic: compressedImg };
    try {
      auth.updateUser(userData, (type) => {
        if (type?.type === "success") {
          Swal.fire({
            title: "User Updated Successfully!!",
            icon: "success",
          });
          setIsLoading(false);
        } else {
          Swal.fire({
            title: "Error!",
            text: type?.data?.data,
            icon: "error",
          });
          setIsLoading(false);
        }
      });
    } catch (error) {
      toast({
        title: "Error!",
        description: error?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  });

  useEffect(() => {
    getCurrentUser();
  }, []);

  const resetPassword = async () => {
    setPreviousPassError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setErrorMessage("");

    if (!previousPass || !newpassword || !confirmPassword) {
      setErrorMessage("Please fill in all fields");
      setPreviousPassError(!previousPass);
      setNewPasswordError(!newpassword);
      setConfirmPasswordError(!confirmPassword);
      return;
    }

    if (newpassword !== confirmPassword) {
      setConfirmPasswordError(true);
      setErrorMessage("Password doesn't match");
      return;
    } else {
      setConfirmPasswordError(false);
    }

    const requestData = {
      newpassword: newpassword,
      previousPass: previousPass,
    };
    try {
      const response = await apiPUT("/v1/auth/resetPassword", requestData);

      if (response?.status === 200) {
        handleCloseChangePasswordModal();
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Password reset successfully.',
        });
        setIsLoading(false);
      } else {
        handleCloseChangePasswordModal();
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response?.data?.data,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text:"Password Not Match",
      });
      setIsLoading(false);
    }
  };

  const compressImage=async()=>{
    preventDefault()
    try{
    let originalImage = `data:image/jpeg;base64,${base64Image}`
    
    const img = new Image();
      img.src = originalImage;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const container = document.createElement("canvas");
      container.width = img.width;
      container.height = img.height;

      const ctx = container.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const compressedImage = container.toDataURL("image/jpeg", 0.1).slice(23);
      setCompressedImg(compressedImage)
    }catch(error){
      console.error(error)
    }
  }
useEffect(()=>{
  compressImage()
}
  
,[base64Image])

  return (
    <StyledForm action="#" method="post" id={`settings_${type}`}>
      <div className="wrapper ">
        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? "active" : ""}`}
        >
          <input
            {...getInputProps({
              accept: "image/jpeg, image/png, image/jpg",
            })}
          />
          {base64Image ? (
            <StyledImage
              src={`data:image/png;base64,${base64Image}`}
              alt="Selected"
              className="selected-image"
            />
          ) : (
            <p>{hint}</p>
          )}
        </div>
        <Container>
          <LabeledFormInput
            id="profileFullName"
            title="Full Name"
            handler={(e) => {
              setUserData({ ...userData, name: e.target.value });
            }}
            value={userData?.name}
          />
          <LabeledFormInput
            id="profileEmail"
            title="Email"
            handler={(e) => {
              setUserData({ ...userData, email: e.target.value });
            }}
            value={userData?.email}
          />

          <LabeledFormInput
            id="profilePhone"
            title="Phone"
            input="number"
            handler={(e) => {
              let value = e.target.value.replace(/[^0-9]/g, "");
              let formattedValue = "";

              for (let i = 0; i < value.length && i < 10; i++) {
                if (i === 3 || i === 6) {
                  formattedValue += "-";
                }
                formattedValue += value[i];
              }

              if (value.length <= 13) {
                setUserData({ ...userData, phoneNo: formattedValue });
              }
            }}
            value={userData?.phoneNo}
          />
          <LabeledFormInput
            id="profilePostalCode"
            title="Postal Code"
            handler={(e) => {
              let value = e.target.value;
              value = value.toUpperCase();
              value = value.replace(/[^A-Z0-9]/g, "");
              value = value.replace(/(.{3})/g, "$1 ");
              value = value.trim();
              if (value.length <= 7) {
                setUserData({ ...userData, postalCode: value });
              }
            }}
            value={userData?.postalCode}
          />
          <LabeledFormInput
            id="profilestreetAddress"
            title="Street Address"
            handler={(e) => {
              setUserData({ ...userData, streetAddress: e.target.value });
            }}
            value={userData?.streetAddress}
          />
          <LabeledFormInput
            id="profileProvince"
            title="Province"
            handler={(e) => {
              setUserData({ ...userData, province: e.target.value });
            }}
            value={userData?.province}
          />
          <LabeledFormInput
            id="profilcity"
            title="City"
            handler={(e) => {
              setUserData({ ...userData, city: e.target.value });
            }}
            value={userData?.city}
          />
        </Container>
      </div>

      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
          flexWrap: "wrap-reverse",
        }}
      >
        <Button
          onClick={handleOpenChangePasswordModal}
          style={{
            color: "white",
            width: window.innerWidth <= 768 ? "100%" : "180px",
            backgroundColor: "#1ecdd4",
            fontWeight: "bold",
            marginRight: window.innerWidth <= 768 ? "0" : "10px",
          }}
        >
          Change Password
        </Button>
        <Button
          onClick={updateUser}
          style={{
            color: "white",
            width: window.innerWidth <= 768 ? "100%" : "180px",
            backgroundColor: "#1ecdd4",
            fontWeight: "bold",
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={18} />
          ) : (
            <div>save</div>
          )}
        </Button>
      </Container>

      <Dialog
        open={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: "12px" }}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Previous Password"
                fullWidth
                size="small"
                required
                type={showPreviousPassword ? "text" : "password"}
                value={previousPass}
                onChange={(e) => {
                  setPreviousPass(e.target.value);
                  setPreviousPassError(false);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPreviousPassword(!showPreviousPassword)}
                        edge="end"
                      >
                        {showPreviousPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isPreviousPassError}
                helperText={isPreviousPassError ? "Please enter your previous password" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="New Password"
                fullWidth
                size="small"
                required
                type={showNewPassword ? "text" : "password"}
                value={newpassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordError(false);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isNewPasswordError}
                helperText={isNewPasswordError ? "Please enter new password" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Confirm New Password"
                fullWidth
                required
                error={isConfirmPasswordError}
                size="small"
                type={showConfirmNewPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false); // Reset error state when typing
                  setErrorMessage(""); // Reset general error message
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                        edge="end"
                      >
                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                
                helperText={isConfirmPasswordError ? "Please enter confirm password" : ""}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              style={{ padding: "10px" }}
              onClick={handleCloseChangePasswordModal}
            >
              Cancel
            </Button>
            <Button
              style={{ padding: "10px" }}
              type="submit"
              variant="contained"
              color="primary"
              onClick={resetPassword}
            >
              {isPassLoader && <CircularProgress sx={{ color: "white" }} size={18} />}
              {!isPassLoader && <div>Save Changes</div>}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </StyledForm>
  );
};

Form.propTypes = {
  type: PropTypes.oneOf(["patient", "doctor"]).isRequired,
};

export default Form;
