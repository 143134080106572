// styled components
import { Card, Info, Content } from "./style";

// components
import Widget from "@components/Widget";
import WidgetBody from "@components/Widget/WidgetBody";
import CountUp from "react-countup";

// SVG components
import { ReactComponent as AssignDeliverySvg } from "../../assets/assign_delivery.svg";
import { ReactComponent as CurrentDeliverySvg } from "../../assets/current_delivery.svg";
import { ReactComponent as OrderDeliveredSvg } from "../../assets/order_delivered.svg";

const Statistics = ({ data }) => {
  const Icon = () => {
    const svgStylesDriver = {
      width: "80px",
      height: "80px",
      fill: "white",
    };
    switch (data.type) {
      default:
      case "assignDelivery":
        return <AssignDeliverySvg style={svgStylesDriver} />;
      case "currentDelivery":
        return <CurrentDeliverySvg style={svgStylesDriver} />;
      case "successfullDelivery":
        return <OrderDeliveredSvg style={svgStylesDriver} />;
    }
  };

  const color = () => {
    switch (data.type) {
      default:
      case "assignDelivery":
        return "blue";
      case "currentDelivery":
        return "teal";
      case "successfullDelivery":
        return "green";
    }
  };

  return (
    <Widget name="Statistics">
      <WidgetBody>
        <Content>
          <Card className={color()}>
            <Icon />
          </Card>
          <Info>
            <CountUp
              className="value"
              start={0}
              end={data.value}
              delay={0}
              duration={1}
              enableScrollSpy
            />
            <p className="h3">{data.text}</p>
          </Info>
        </Content>
      </WidgetBody>
    </Widget>
  );
};

export default Statistics;
