// pages

import AdminDashboard from "./pages/AdminDashboard";
import Users from "./pages/Users";
import ChemistDashboard from "./pages/ChemistDashboard";
import Customers from "./pages/Customers";
import Driver from "./pages/Driver";
import Orders from "./pages/Orders";
import Settings from "./pages/Settings";
import Login from "./pages/login/Login";
import PageNotFound from "./pages/PageNotFound";
import AdminOrderDashboard from "./pages/adminOrderDashboard";
import DriverOrders from "@widgets/DriverList/DriverOrders";
import DeliveredOrders from "./pages/DeliveredOrders";
import GenerateInvoice from "@pages/GenerateInvoice";

const authProtectedRoutes = [
  // { path: "/", element: <AdminDashboard /> },
  // { path: "/admin-dashboard", element: <AdminDashboard /> },

  { path: "/chemistdashboard", element: <ChemistDashboard /> },
  { path: "/customers", element: <Customers /> },
  { path: "/404", element: <PageNotFound /> },
];

// These paths are relative to /dashboard/
const adminRoutes = [
  { path: "/dashboard", element: <AdminDashboard /> },
  { path: "/orders", element: <AdminOrderDashboard /> },
  { path: "/profile", element: <Settings /> },
  { path: "/driver", element: <Driver /> },
  { path:"/generateInvoice" ,element :<GenerateInvoice/>}
];

const chemistRoute = [
  { path: "/dashboard", element: <ChemistDashboard /> },
  { path: "/users", element: <Users /> },
  { path: "/orders", element: <Orders /> },
  { path: "/profile", element: <Settings /> },
];

const driverRoute = [
  { path: "/dashboard", element: <Driver /> },
  { path: "/orders", element: <DriverOrders /> },
  { path: "/profile", element: <Settings /> },
  { path: "/deliveredorders", element: <DeliveredOrders /> },
];

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/", element: <Login /> },
];

export { chemistRoute, adminRoutes, driverRoute, publicRoutes };
