import React, { useState , useEffect } from "react";
import {
  List,
  MainItem,
} from "../style";
import { colors } from "@styles/vars";
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";
import { useSidebarContext } from "@contexts/sidebarContext";
import menu from "@constants/menu";
import { useLocation } from 'react-router-dom';

const Content = () => {
  let location=useLocation()
  const { toggleSidebar } = useSidebarContext();
  const [isActive, setIsActive] = useState(location.pathname);

  const handleUpdLocation =()=>{
    setIsActive(location.pathname)
  }

  useEffect(
    handleUpdLocation
  ,[location])

  const activeStyle = { color: colors.blue };

  return (
    <List as={Accordion}>
      {menu.map((item, index) => {
        if (item.cat) {
          return (
            <Accordion.Item eventKey={item.cat} key={item.cat} >
              <MainItem
                style={item.link === isActive ? activeStyle : undefined}
                as={NavLink}
                to={item.link}
                onClick={() => {
                  toggleSidebar();
                }}
              >
                {item.icon} {item.cat}
              </MainItem>
            </Accordion.Item>
          );
        } else if (item.link) {
          return (
            <MainItem
              as={NavLink}
              to={item.link}
              onClick={() => toggleSidebar()}
              style={item.link === isActive ? activeStyle : undefined}
              key={item.link}
              className={index === menu.length - 1 ? "pin-down" : ""}
            >
              <i className={`icon icon-${item.icon}`}></i> {item.name}
            </MainItem>
          );
        } else return null;
      })}
    </List>
  );
};

export default Content;
