// styled components
import { Actions, Header, Input, Label, Search } from "./style";

// components
import Logo from "@ui/Logo";
import MenuButton from "@ui/MenuButton";
import ShapeButton from "@ui/ShapeButton";
import { motion } from "framer-motion";
import CurrentUser from "@layout/Panel/CurrentUser";

// hooks
import useWindowSize from "@hooks/useWindowSize";
import usePanelScroll from "@hooks/usePanelScroll";
import { useSidebarContext } from "@contexts/sidebarContext";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router";

const Panel = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const isDesktop = width >= 1366;
  const classname = usePanelScroll();
  const { isSidebarOpen } = useSidebarContext();
  const headerRef = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerRef.current.offsetHeight}px`
    );
  }, [width]);

  return (
<div style={{height:"50px", background: "transparent", position: "relative", alignSelf: "flex-end", "box-shadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px" ,borderRadius:"10px"}}>
    <Header
      as={motion.header}
      animate={{ y: isSidebarOpen && isMobile ? "-100%" : 0 }}
      transition={{ duration: 0.3, ease: "linear", type: "tween" }}
      className={classname}
      ref={headerRef}
      style={{background:"transparent",height:"50px",marginLeft:"5px",}}
    >
      {!isDesktop && (
        <div className="logo-wrapper" style={{ paddingTop:"10px"}}>
          <Logo compact={isMobile} />
        </div>
      )}
      {/* <Search>
                <Input type="search" id="globalSearch" placeholder={width < 414 ? 'Search' : 'Search patients or doctors'}/>
                <Label htmlFor="globalSearch">
                    <i className="icon icon-search"></i>
                </Label>
            </Search> */}
        {isMobile ? (
          <MenuButton />
        ) : (
          <Actions>
            {/* <ShapeButton
            shape="square"
            label="Notification"
            icon="bell"
            hasNotification={true}
          /> */}
            {isDesktop ? (

              <CurrentUser />

            )
              : (
                <ShapeButton onClick={() => navigate("/settings")} shape="round" label="Profile" icon="user" />
              )}
            {width < 1366 && <MenuButton />}
          </Actions>
        )}
      </Header>
    </div>
  );
};

export default Panel;
