// utils
import {
  lazy,
} from "react";

// components
import ScrollProgress from "@ui/ScrollProgress";
import Sidebar from "@layout/Sidebar";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import BottomMenu from "@layout/BottomMenu";
import {
  authProtectedRoutes,
  publicRoutes,
  adminRoutes,
  chemistRoute,
  driverRoute,
} from "./routes";
import useWindowSize from "@hooks/useWindowSize";
import usePageIsOverflow from "@hooks/usePageIsOverflow";
import { useRef, useEffect } from "react";
import { useState } from "react";
import AuthRequired from "./contexts/auth/authRequired";
import Panel from "@layout/Panel";

const PageNotFound = lazy(() => import("./pages/PageNotFound"));

const AppLayout = () => {
  const appRef = useRef(null);
  const isOverflow = usePageIsOverflow();
  const { width } = useWindowSize();
  const [userDetails, setuserDetails] = useState();
  const isMobile = width < 768;
  const navigate = useNavigate();

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("user"));
    if (users) {
      setuserDetails(users);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="app" ref={appRef}>
      {isOverflow && <ScrollProgress />}

      <Routes>
        {publicRoutes?.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
        <Route
          path="/"
          errorElement={<PageNotFound />}
          element={
            <AuthRequired>
              <div className="app_content">
                <Sidebar />
                <Panel />
                <Outlet />
              </div>
            </AuthRequired>
          }
        >
          {userDetails && userDetails?.role === "admin"
            ? adminRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={route.element}
                errorElement={<PageNotFound />}
                key={idx}
              />
            ))
            : userDetails && userDetails?.role === "driver"
              ? driverRoute.map((route, idx) => (
                <Route
                  path={route.path}
                  element={route.element}
                  errorElement={<PageNotFound />}
                  key={idx}
                />
              ))
              : userDetails && userDetails?.role === "chemist"
                ? chemistRoute.map((route, idx) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    errorElement={<PageNotFound />}
                    key={idx}
                  />
                ))
                : ""}
        </Route>
      </Routes>
    </div>
  );
};

export default AppLayout;
