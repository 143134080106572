import Page from "@layout/Page";
import UserSettings from "@widgets/UserSettings";

const Settings = () => {
  return (
    <Page title="Profile">
      <UserSettings />
    </Page>
  );
};

export default Settings;
