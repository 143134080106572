import React, { useState } from "react";
import Page from "@layout/Page";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { QrScanner } from "@yudiel/react-qr-scanner";
import QRScanner from "./QRScanner";
import { apiPUT } from "@utils/apiHelper";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeliveredOrders = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [scannerError, setScannerError] = useState(null);
  const [deliveryData, setDeliveryData] = useState("");
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setScannerError(null);
  };

  const handleOutDelivery = async () => {
    const result = await apiPUT(
      `/v1/order/orderDeliver/${deliveryData.id}`
    );
    if (result.status === 200) {
      Swal.fire({
        title: "Success",
        text: "Order Delivered successfully",
        icon: "success",
      });
      setDeliveryData();
    } else {
      Swal.fire({
        title: "Error",
        text: result.data.data,
        icon: "error",
      });
    }
  };
  
  const handleScan = (data) => {
    if (data) {
      setDeliveryData(JSON.parse(data));
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      setScannerError("No QR code detected");
    }
  };

  const handleError = (err) => {
    setScannerError(err.message || "Failed to access camera");
  };

  return (
    <>
      <Page title="Delivered Orders">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          {deliveryData ? (
            <Button
              onClick={handleOutDelivery}
              variant="outlined"
              sx={{
                fontSize: "30px",
                borderRadius: "30px",
                "&:hover": {
                  backgroundColor: "lightGrey",
                },
              }}
            >
           Click to Deliver Order
            </Button>
          ) : (
            <Button
              onClick={handleOpen}
              sx={{
                color:"white",
                backgroundColor: "#1ecdd4",
                fontSize: "30px",
                borderRadius: "30px",
                padding: "12px",
                "&:hover": {
                  backgroundColor: "#20d9df",
                  transform: "scale(1.05)",
                  color:"white"
                },
              }}
            >
             Scan to Deliver Order
            </Button>
          )}{" "}
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <QRScanner />
              {scannerError && (
                <Typography color="error">{scannerError}</Typography>
              )}
              <QrScanner onDecode={handleScan} onError={handleError} />
            </Box>
          </Modal>
        </div>
      </Page>
    </>
  );
};

export default DeliveredOrders;
