import React, { useState, useEffect } from "react";
import { apiGET, apiPUT } from "@utils/apiHelper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Swal from "sweetalert2";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from "@mui/icons-material/Clear";
import DrawIcon from "@mui/icons-material/Draw";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Page from "@layout/Page";
import CircularProgress from "@mui/material/CircularProgress";
import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import Calendar from "react-multi-date-picker";
import { useToast } from "@chakra-ui/react";

export default function DriverList({
  stateValue,
  analyticdeliveryStatus,
  setAnalyticdeliveryStatus,
  getAnalyticsData,
}) {
  const [orders, setOrders] = useState([]);
  const [isCustomerModalOpen, setIsCustomersModalOpen] = useState(false);
  const [customerChemistModelContent, setCustomerChemistModelContent] =
    useState([]);
  const [productsModalContent, setProductsModalContent] = useState([]);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalResult, setTotalResult] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSignatureModel, setOpenSignatureModel] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [searchDates, setSearchDates] = useState([]);
  const [destructuredSearchDates, setDestructuredSearchedDates] = useState({});
  const toast = useToast();
  const [driverNotes, setDriverNotes] = useState("");
  const [driverNotesModalOpen, setDriverNotesModalOpen] = useState(false);
  const [driverNotesId,setDriverNotesId]= useState()

  const handleSearchDate = () => {
    if (searchDates.length == 2) {
      const startDate = `${searchDates[0].year}/${String(
        searchDates[0].day
      ).padStart(2, "0")}/${String(searchDates[0].monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      const endDate = `${searchDates[1].year}/${String(
        searchDates[1].day
      ).padStart(2, "0")}/${String(searchDates[1].monthIndex + 1).padStart(
        2,
        "0"
      )}`;
      setDestructuredSearchedDates({ fromDate: startDate, toDate: endDate });
    }
  };

  useEffect(() => {
    if (searchDates === null || searchDates.length === 0) {
      setDestructuredSearchedDates({ fromDate: null, toDate: null });
    } else {
      handleSearchDate();
    }
  }, [searchDates]);

  const [deliveyId, setDeliveryId] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewProducts = (orderId) => {
    const order = orders.find((order) => order?._id === orderId);
    if (order) {
      const products = order.product;
      setProductsModalContent(products);
    } else {
      toast({
        title: "Error!",
        description: "Order not foun!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewCustomers = (orderId) => {
    const order = orders?.find((order) => order._id === orderId);
    if (order) {
      const customers = order.customer;
      const chemist = order.chemist;
      const paybleAmount = order.paybleAmount;
      const notes = order.notes;
      setCustomerChemistModelContent([
        { customer: customers },
        { chemist: chemist },
        { paybleAmount: paybleAmount },
        { notes: notes },
      ]);
      
      setIsCustomersModalOpen(true);
    } else {
      toast({
        title: "Error!",
        description: "Order not foun!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseCustomerModal = () => {
    setIsCustomersModalOpen(false);
    setCustomerChemistModelContent([]);
  };

  const getQueryString = (params) => {
    return Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  };

  const getAllOrders = async () => {
    setIsLoading(true);
    try {
      const queryParams = {
        limit: rowsPerPage ? rowsPerPage : 10,
        page: page ? page : 1,
      };
      let deliveryStatusObject;
      if (analyticdeliveryStatus) {
        deliveryStatusObject = {
          ...deliveryStatusObject,
          deliveryStatus: Number(analyticdeliveryStatus),
        };
      }
      if (searchQuery) {
        deliveryStatusObject = {
          ...deliveryStatusObject,
          orderId: searchQuery,
        };
      }

      if (destructuredSearchDates?.fromDate && destructuredSearchDates.toDate) {
        deliveryStatusObject = {
          ...deliveryStatusObject,
          fromDate: destructuredSearchDates?.fromDate,
          toDate: destructuredSearchDates?.toDate,
        };
      }
      queryParams.filter = JSON.stringify(deliveryStatusObject);

      const response = await apiGET(
        `/v1/order/getAllOrderDriver?${getQueryString(queryParams)}`
      );

      if (response.status === 200) {
        setOrders(response?.data?.data?.data);
        setPage(response?.data?.data?.page);
        setrowsPerPage(response?.data?.data?.limit);
        setTotalResult(response?.data?.data?.totalResults);
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getEndIndex = () => {
    const endIndex = page * rowsPerPage;
    return endIndex > totalResult ? totalResult : endIndex;
  };

  const getStartIndex = () => {
    return (page - 1) * rowsPerPage + 1;
  };

  const handleSignatureModelOpen = () => {
    setOpenSignatureModel(true);
  };

  const handleCloseSignatureModel = () => {
    setOpenSignatureModel(false);
  };

  const signatureRef = useRef();

  const handleSignatureData = async () => {
    if (signatureRef.current) {
      let canvas = signatureRef.current.toDataURL();
      const img = new Image();
      img.src = canvas;

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const container = document.createElement("canvas");
      container.width = img.width;
      container.height = img.height;

      const ctx = container.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const compressedImage = container.toDataURL("image/jpeg", 0.1);
      setSignatureData(compressedImage);
    }
  };

  const getSignatureData = async () => {
    try {
      if (signatureData) {
        const response = await apiPUT(
          `/v1/order/deliverOrderBySign/${deliveyId}`,
          { sign: signatureData }
        );
        if (response.status === 200) {
          setOpenSignatureModel(false);
          getAllOrders();
          getAnalyticsData();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Order Delivered",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setOpenSignatureModel(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.data,
          });
        }
      }
    } catch (error) {
      setOpenSignatureModel(false);
      toast({
        title: "Error!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    getAllOrders();
  }, [
    searchQuery,
    page,
    rowsPerPage,
    analyticdeliveryStatus,
    destructuredSearchDates?.fromDate && destructuredSearchDates?.toDate,
    stateValue,
  ]);

  const handleClearFilter = () => {
    setTimeout(() => {
      setSearchDates([]);
      setSearchQuery("");
      setAnalyticdeliveryStatus();
      setDestructuredSearchedDates({});
      getAllOrders();
      getAnalyticsData();
    }, 10);
  };

  const openMap = async () => {
    let addresses;
    try {
      const response = await apiGET("/v1/order/getMyRoute");
      addresses = response?.data?.data?.data;
    } catch (error) {
      toast({
        title: "Error!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!addresses || addresses.length === 0) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = `${position.coords.latitude},${position.coords.longitude}`;

          const waypoints = addresses.slice(0, -1).join(" / ");

          const mapUrl = `https://www.google.com/maps/dir/${userLocation}/${waypoints}/${
            addresses[addresses.length - 1]
          }`;
          window.open(mapUrl, "_blank");
        },
        (error) => {
          toast({
            title: "Error!",
            description: error,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      );
    } else {
      toast({
        title: "Error!",
        description: "Geolocation is not supported by this browser",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDriverNotesModalOpen = () => {
    setDriverNotesModalOpen(true);
  };

  const handleCloseDriverNotesModal = () => {
    setDriverNotes("")
    setDriverNotesModalOpen(false);
  };

  const addDriverNotes = async() =>{
    try{
      const response = await apiPUT(`/v1/order/addDriverNotes/${driverNotesId}`,{driverNotes:driverNotes})
      console.log("response",response)
      
      if (response.status==200){
        setDriverNotesModalOpen(false)
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Notes Added",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (driverNotes == ""){
        toast({
          title: "Warning!",
          description: "Please Add A Note",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }

    }catch(error){
      toast({
        title: "Error!",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }finally{
      getAllOrders()
    }
  }

  const getDriverNotes=(orderId)=>{
    const order = orders?.find((order) => order._id === orderId);
    order && setDriverNotes(order.driverNotes)
}


  return (
    <>
      {openSignatureModel && (
        <div
          style={{
            height: window.innerWidth > 767 ? "380px" : "65vh",
            width: window.innerWidth > 767 ? "400px" : window.innerWidth,
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            backgroundColor: "white",
            zIndex: 9999,
            maxWidth: "90%",
            maxHeight: "90%",
            overflow: "auto",
          }}
        >
          <SignatureCanvas
            ref={signatureRef}
            penColor="white"
            canvasProps={{
              className: "signature-canvas",
              style: {
                background: "black",
                height: window.innerWidth > 767 ? "330px" : "60vh",
                width: window.innerWidth > 767 ? "100%" : window.innerWidth,
              },
            }}
            onEnd={handleSignatureData}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "5px",
              margin: "5px",
            }}
          >
            <Button variant="contained" onClick={clearSignature} size="small">
              Clear
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleCloseSignatureModel}
            >
              Close
            </Button>
            <Button size="small" variant="contained" onClick={getSignatureData}>
              Done
            </Button>
          </div>
          ;
        </div>
      )}

      <Dialog
        open={isCustomerModalOpen}
        onClose={handleCloseCustomerModal}
        fullWidth
        maxWidth="md"
        style={{
          overflow: "auto",
          ...(window.innerWidth >= 768 && { marginLeft: 250 }),
        }}
      >
        <div
          style={{
            fontSize: "1.8rem",
            fontWeight: "400",
            margin: "20px 0 0 15px",
          }}
        >
          <u>Order Details</u>
        </div>
        <DialogTitle
          style={{ marginTop: "10px", paddingTop: 0, paddingBottom: 0 }}
        >
          Customer Details:
        </DialogTitle>
        <DialogContent>
          {customerChemistModelContent?.length > 0 && (
            <TableContainer style={{ paddingLeft: "20px" }}>
              <Table>
                <TableBody>
                  <Typography>
                    {" "}
                    Name : {customerChemistModelContent[0]?.customer?.name}
                  </Typography>
                  <Typography>
                    Address :{" "}
                    {customerChemistModelContent[0]?.customer?.streetAddress}{" "}
                    {customerChemistModelContent[0]?.customer?.city}{" "}
                    {customerChemistModelContent[0]?.customer?.province}{" "}
                    {customerChemistModelContent[0]?.customer?.postalCode}
                  </Typography>
                  <Typography>
                    Phone Number :{" "}
                    {customerChemistModelContent[0]?.customer?.phoneNo}
                  </Typography>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogTitle style={{ marginTop:3, paddingTop: 0, paddingBottom: 0 }}>
          Chemist Details:
        </DialogTitle>
        <DialogContent>
          {customerChemistModelContent?.length > 0 && (
            <TableContainer style={{ paddingLeft: "20px" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <Typography>
                      Name : {customerChemistModelContent[1]?.chemist?.name}
                    </Typography>

                    <Typography>
                      Address :{" "}
                      {customerChemistModelContent[1]?.chemist?.streetAddress}{" "}
                      {customerChemistModelContent[1]?.chemist?.city}{" "}
                      {customerChemistModelContent[1]?.chemist?.province}{" "}
                      {customerChemistModelContent[1]?.chemist?.postalCode}
                    </Typography>
                    <Typography>
                      Phone Number :
                      {customerChemistModelContent[1]?.chemist?.phoneNo}
                    </Typography>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogTitle style={{ marginTop: 3,paddingTop: 0, paddingBottom: 0 }}>
          Chemist Notes:
        </DialogTitle>
        <DialogContent>
          <Typography style={{ paddingLeft: "20px" }}>
            {customerChemistModelContent[3]?.notes}
          </Typography>
        </DialogContent>

        <DialogTitle style={{marginTop:-10, paddingTop: 0, paddingBottom: 0 }}>
          Your Notes:
        </DialogTitle>
        <DialogContent>
          <Typography style={{ paddingLeft: "20px" }} paddingInline={5}>
            {driverNotes}
          </Typography>
        </DialogContent>
        <DialogTitle style={{ paddingTop: 0, paddingBottom: 0 }}>
          View Products:
        </DialogTitle>
        {productsModalContent != null && (
          <DialogContent style={{ paddingLeft: "20px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No. of medications</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Payble Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productsModalContent?.length > 0 &&
                    productsModalContent.map((product, index) => (
                      <TableRow key={index}>
                        <TableCell>{product?.noOfPersons}</TableCell>
                        <TableCell>{product?.quantity}</TableCell>
                        <TableCell>{product?.price}</TableCell>
                        <TableCell>
                          {customerChemistModelContent[2]?.paybleAmount}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={handleCloseCustomerModal}
            style={{ padding: "10px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={driverNotesModalOpen}
        onClose={handleCloseDriverNotesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius:"8px"
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Notes
          </Typography>
          <TextField
            value={driverNotes}
            onChange={(e) => setDriverNotes(e.target.value)}
            multiline
            rows={5}
            style={{ marginTop: "2rem", width: "100%" }}
            size="large"
            id="outlined-basic"
            label="Notes"
            variant="outlined"
          />

          <div
            style={{
              marginTop:"2rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button sx={{marginRight:"5px"}} variant="contained" onClick={handleCloseDriverNotesModal}>Close</Button>
            <Button variant="contained" onClick={addDriverNotes}>Save</Button>
          </div>
        </Box>
      </Modal>

      <div
        style={{
          overflowX: "auto",
          maxHeight: "69vh",
          marginTop: "30px",
          padding: "0",
        }}
      >
        <Paper elevation={3}>
          <div style={{ position: "sticky", top: 0, zIndex: 3 }}>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                minWidth: "fit-content",
                justifyContent: "space-between",
                padding: "10px",
                height: "4rem",
                alignItems: "center",
                borderBottom: "1px solid #444",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  padding: "10px",
                }}
              >
                <InputBase
                  placeholder="Search by OrderId"
                  value={searchQuery}
                  style={{ minWidth: "150px", maxWidth: "400px" }}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
                <Calendar
                  variant="outlined"
                  size="medium"
                  placeholder="Select date range"
                  style={{
                    height: "40px",
                    minWidth: "200px",
                    maxWidth: "400px",
                  }}
                  value={searchDates}
                  onChange={setSearchDates}
                  range
                  rangeHover
                />
                <div style={{ marginRight: "0", paddingTop: "1px" }}>
                  <Button
                    onClick={handleClearFilter}
                    variant="contained"
                    disabled={
                      !searchDates?.length > 0 &&
                      !searchQuery &&
                      !analyticdeliveryStatus
                    }
                    size="medium"
                    startIcon={<ClearIcon />}
                    sx={{
                      height: "40px",
                      width: "10rem",
                      borderRadius: "5px",
                    }}
                  >
                    Clear Filter
                  </Button>
                </div>
              </div>
              <div style={{ paddingTop: "3px" }}>
                <Button
                  variant="contained"
                  sx={{ height: "40px", width: "9rem", borderRadius: "5px" }}
                  startIcon={<LocationOnIcon />}
                  onClick={openMap}
                >
                  Open Map
                </Button>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "0" }}>
            <TableContainer
              style={{
                height: "auto",
                width: "100%",
                overflow: "auto",
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "24px",
                    justifyContent: "center",
                  }}
                >
                  {isLoading && <CircularProgress />}
                </div>
              ) : (
                <Table
                  style={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr No.</TableCell>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Payable Amount</TableCell>
                      <TableCell>Payment Status</TableCell>
                      <TableCell>Payout</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Actions
                      </TableCell>
                      <TableCell>Delivery</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: "80%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  {!isLoading && (
                    <TableBody>
                      {orders?.length > 0 &&
                        orders.map((row, index) => (
                          <TableRow key={row._id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row?.orderId}</TableCell>
                            <TableCell>{row?.paybleAmount}</TableCell>
                            <TableCell>
                              {row?.paymentStatus === 1
                                ? "COD"
                                : row?.paymentStatus === 0
                                ? "Prepaid"
                                : ""}
                            </TableCell>
                            <TableCell>{row.driverPayout ?? "N/A"}</TableCell>
                            <TableCell>
                              {moment(row?.customCreatedAt).format("ll")}
                            </TableCell>
                            <TableCell style={{ width: "8rem" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                  paddingLeft: "1rem",
                                }}
                              >
                                <Button
                                  title="View Customer"
                                  variant="outlined"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewCustomers(row?._id);
                                    getDriverNotes(row?._id)
                                    handleViewProducts(row?._id);
                                  }}
                                  style={{
                                    border: "none",
                                    width: "2rem",
                                    height: "2rem",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <VisibilityIcon />
                                </Button>

                                <Button
                                  title="Add Notes"
                                  variant="outlined"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDriverNotesId(row?._id)
                                    getDriverNotes(row?._id)
                                    handleDriverNotesModalOpen();
                                  }}
                                  style={{
                                    border: "none",
                                    width: "2rem",
                                    height: "2rem",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                              </div>
                            </TableCell>
                            <TableCell style={{ width: "8rem" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                <Button
                                  title="Signature"
                                  variant="contained"
                                  disabled={row?.deliveryStatus <= 1}
                                  startIcon={<DrawIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSignatureModelOpen();
                                    setDeliveryId(row?._id);
                                  }}
                                  style={{
                                    border: "none",
                                    height: "2rem",
                                    width: "5rem",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Sign
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </div>
          <div
            style={{
              position: "sticky",
              bottom: -1,
              zIndex: 1,
              backgroundColor: "white",
              marginTop: "2px",
            }}
          >
            <TablePagination
              component="div"
              count={totalResult}
              page={page}
              backIconButtonProps={{
                disabled: page === 1,
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return `${getStartIndex()}-${getEndIndex()} of ${totalResult}`;
              }}
              nextIconButtonProps={{
                disabled: page * rowsPerPage >= totalResult,
              }}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
    </>
  );
}
