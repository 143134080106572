import React from 'react'
import Page from '@layout/Page';
import DoctorsList from '@widgets/DoctorsList';

const Customers = () => {
  return (
    <>
      <Page title="Customers List">
        <DoctorsList />
      </Page>
    </>
  )
}

export default Customers
